/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from "react";
import LDPLayout from "../../components/LDPLayout";
import { Badge, Col, Input, Row, Select, Tag, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { MyContext } from "../../App";
import { dataHard, totalHard } from "../../data";
import { ReactComponent as IconClook } from "../../assets/IconClook.svg";
import { ReactComponent as IconOpenTab } from "../../assets/openTab.svg";
import moment from "moment";

type Props = {};

type Item = {
  type: string;
  page: string;
  title?: string;
  report: string;
  link: string;
  hyperLink: string;
  typeLink: string;
  dateTime?: string;
  ID: string;
  Content?: string;
}[];

const { Paragraph } = Typography;

const HomePage = (props: Props) => {
  const {
    sider: { activeMenu, activeSubMenu, typeSubMenu },
    setIframe,
    iframe,
  } = useContext(MyContext);

  const [param, setParam] = useState<{
    sort: string;
    search: string;
  }>({
    search: "",
    sort: "0",
  });

  const DataRender = useMemo(() => {
    setIframe("");
    let list: Item = [];

    if (activeMenu) {
      list = dataHard.filter((e) => e.type === activeMenu);
    }

    if (activeSubMenu) {
      list = [...list].filter((e) => e.page === activeSubMenu);
    }

    if (typeSubMenu) {
      list = [...list].filter((e) => e.title === typeSubMenu);
    }

    if (param.search) {
      list = [...list].filter((e) =>
        e.report?.toLowerCase().includes(param.search.toLowerCase())
      );
    }

    list = list.map((e) => {
      return {
        ...e,
        dateTime: moment()
          .add(Math.floor(Math.random() * 8), "days")
          .format("DD/MM/YYYY HH:mm:ss"),
      };
    });

    switch (param.sort) {
      case "0": //a-z
        list = list.sort((a, b) => {
          const reportA = a.report.toLowerCase().trim();
          const reportB = b.report.toLowerCase().trim();

          if (reportA < reportB) {
            return -1;
          }
          if (reportA > reportB) {
            return 1;
          }
          return 0;
        });
        break;
      case "1": //z-a
        list = list.sort((a, b) => {
          const reportA = a.report.toLowerCase().trim();
          const reportB = b.report.toLowerCase().trim();

          if (reportA > reportB) {
            return -1;
          }
          if (reportA < reportB) {
            return 1;
          }
          return 0;
        });
        break;
      case "2": //new
        list = list.sort(
          (a, b) => moment(a.dateTime).valueOf() - moment(b.dateTime).valueOf()
        );
        break;

      case "3": //EN
        const sortAToZ = list.sort((a, b) => {
          const reportA = a.report.toLowerCase().trim();
          const reportB = b.report.toLowerCase().trim();

          if (reportA < reportB) {
            return -1;
          }
          if (reportA > reportB) {
            return 1;
          }
          return 0;
        });

        const EN = sortAToZ.filter((EN) => EN.typeLink === "EN");
        const VN = sortAToZ.filter((EN) => EN.typeLink !== "EN");

        list = [...EN, ...VN];

        break;

      default:
        list = [...list];
        break;
    }

    return list;
  }, [activeMenu, activeSubMenu, typeSubMenu, param]);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setParam((e) => ({ ...e, search: searchTerm }));
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handleInputChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  return (
    <LDPLayout>
      {iframe && (
        <iframe
          src={iframe}
          style={{
            height: "100%",
            width: "100%",
          }}
          title={iframe}
        />
      )}
      {!iframe && (
        <>
          <Row
            justify="space-between"
            gutter={16}
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              padding: "20px 0px",
              background: "#fff",
            }}
          >
            <Col xs={12} lg={6} xxl={4}>
              <Select
                size="large"
                style={{
                  width: "100%",
                }}
                defaultValue="0"
                onChange={(value) => setParam((e) => ({ ...e, sort: value }))}
                options={[
                  { value: "0", label: "Sort by A-Z" },
                  { value: "1", label: "Sort by Z-A" },
                  { value: "2", label: "Sort by Newest" },
                  { value: "3", label: "Sort by language" },
                ]}
              />
            </Col>
            <Col xs={12} lg={18} xxl={20}>
              <Input
                onChange={(event) => handleInputChange(event)}
                size="large"
                placeholder="Search your reports..."
                suffix={<SearchOutlined />}
              />
            </Col>
          </Row>
          {DataRender.map((item, index) => (
            <CarList
              key={index}
              title={item.report}
              typeLink={item.typeLink}
              dateTime={item?.dateTime || ""}
              setLinkIframe={() => setIframe(item.hyperLink)}
              ID={item.ID}
              content={item.Content || ""}
            />
          ))}
        </>
      )}
    </LDPLayout>
  );
};

export default HomePage;

const CarList = ({
  title,
  typeLink,
  setLinkIframe,
  dateTime,
  ID,
  content,
}: {
  title: string;
  typeLink: string;
  dateTime: string;
  setLinkIframe: () => void;
  ID: string;
  content: string;
}) => {
  const url = require(`../../assets/imgReport/${ID}.jpg`);

  const partsSub = content.replace(/\\n/g, "\n");

  const parts = partsSub.split("\n").map((part, index, array) => (
    <React.Fragment key={index}>
      {part}
      {index < array.length - 1 && <br />}
    </React.Fragment>
  ));

  return (
    <Badge.Ribbon
      text="EN"
      color="red"
      style={typeLink !== "EN" ? { visibility: "hidden" } : {}}
    >
      <div
        style={{
          padding: "16px",
          background: "#F2F2F2",
          borderRadius: "14px",
          color: "#111",
          marginTop: "20px",
        }}
      >
        <Row gutter={32}>
          <Col xs={24} lg={8}>
            <img
              src={url}
              alt={url}
              title={url}
              style={{ width: "100%", cursor: "pointer" }}
              onClick={() => setLinkIframe()}
            />
          </Col>
          <Col
            xs={24}
            lg={16}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <p
              style={{
                fontSize: "22px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              {title}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "10px",
                fontSize: "14px",
                fontWeight: 500,
                flexGrow: 1,
                alignContent: "center",
              }}
            >
              <IconClook />
              <span>{dateTime}</span>
            </div>

            <Paragraph
              ellipsis={{ rows: 3, expandable: true, symbol: "See more" }}
            >
              {parts}
            </Paragraph>

            <p
              style={{
                marginBottom: "16px",
                fontSize: "18px",
                fontWeight: 500,
                display: "flex",
                gap: "12px",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setLinkIframe()}
            >
              <span>View detail</span>
              <IconOpenTab />
            </p>
          </Col>
        </Row>
      </div>
    </Badge.Ribbon>
  );
};
