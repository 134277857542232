import Icon from "@ant-design/icons";
import { ReactComponent as Icon1 } from "../../assets/iconSubMenu/1.svg";
import { ReactComponent as Icon2 } from "../../assets/iconSubMenu/2.svg";
import { ReactComponent as Icon3 } from "../../assets/iconSubMenu/3.svg";
import { ReactComponent as Icon4 } from "../../assets/iconSubMenu/4.svg";
import { ReactComponent as Icon5 } from "../../assets/iconSubMenu/5.svg";
import { ReactComponent as Icon6 } from "../../assets/iconSubMenu/6.svg";
import { ReactComponent as Icon7 } from "../../assets/iconSubMenu/7.svg";
import { ReactComponent as Icon8 } from "../../assets/iconSubMenu/8.svg";
import { ReactComponent as Icon9 } from "../../assets/iconSubMenu/9.svg";
import { ReactComponent as Icon10 } from "../../assets/iconSubMenu/10.svg";
import { ReactComponent as Icon11 } from "../../assets/iconSubMenu/11.svg";
import { ReactComponent as Icon12 } from "../../assets/iconSubMenu/12.svg";
import { ReactComponent as Icon13 } from "../../assets/iconSubMenu/13.svg";
import { ReactComponent as Icon14 } from "../../assets/iconSubMenu/14.svg";
import { ReactComponent as Icon15 } from "../../assets/iconSubMenu/15.svg";
import { ReactComponent as Icon16 } from "../../assets/iconSubMenu/16.svg";
import { ReactComponent as Icon17 } from "../../assets/iconSubMenu/17.svg";
import { ReactComponent as Icon18 } from "../../assets/iconSubMenu/18.svg";
import { ReactComponent as Icon19 } from "../../assets/iconSubMenu/19.svg";
import { ReactComponent as Icon20 } from "../../assets/iconSubMenu/20.svg";
import { ReactComponent as Icon21 } from "../../assets/iconSubMenu/21.svg";
import { ReactComponent as Icon22 } from "../../assets/iconSubMenu/22.svg";
import Icon23 from "../../assets/iconSubMenu/24.png";

type Props = {
  keyIcon: string;
};

const IconSelectCustom = (key: string) => {
  switch (key) {
    case "Vietnam Social Security": //23
      return <img src={Icon23} alt="" />;
    case "University": //1
      return <Icon1 />;
    case "E-commerce": //2
      return <Icon2 />;
    case "Finance & Banking": //3
      return <Icon3 />;
    case "Food & Beverage": //4
      return <Icon4 />;
    case "Manufacturing": //5
      return <Icon5 />;
    case "Aviation": //6
      return <Icon6 />;
    case "Air&Water Management": //7
      return <Icon7 />;
    case "Others": //8
      return <Icon8 />;
    case "Natural Resources and Environment": //9
      return <Icon9 />;
    case "Education and Training": //10
      return <Icon10 />;
    case "Transportation": //11
      return <Icon11 />;
    case "Planning and Investment": //12
      return <Icon12 />;
    case "Finance": //13
      return <Icon13 />;
    case "Construction": //14
      return <Icon14 />;
    case "Agriculture and Rural Development": //15
      return <Icon15 />;
    case "Science and Technology": //16
      return <Icon16 />;
    case "Industry and Trade": //17
      return <Icon17 />;
    case "Internal Affairs": //18
      return <Icon18 />;
    case "Labor": //19
      return <Icon19 />;
    case "Health": //20
      return <Icon20 />;
    case "Local Government": //21
      return <Icon21 />;
    case "Vietnam Academy of Science and Technology": //22
      return <Icon22 />;

    default:
      return <Icon22 />;
  }
};

const IconSelectList = (props: Props) => {
  return <Icon component={() => IconSelectCustom(props.keyIcon)} />;
};

export default IconSelectList;
