import Icon from "@ant-design/icons";
type Props = {};

const IconCustom = () => (
  <svg
    width="34"
    height="35"
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="48px_temple">
      <path
        id="Vector"
        d="M31.875 28.8333H30.4583V16.7917H29.0417V28.8333H26.2083V16.7917H24.7917V28.8333H21.9583V16.7917H20.5417V28.8333H17.7083V16.7917H16.2917V28.8333H13.4583V16.7917H12.0417V28.8333H9.20832V16.7917H7.79166V28.8333H4.95832V16.7917H3.54166V28.8333H2.12499C1.93713 28.8333 1.75696 28.908 1.62412 29.0408C1.49128 29.1736 1.41666 29.3538 1.41666 29.5417V32.375C1.41666 32.5629 1.49128 32.743 1.62412 32.8759C1.75696 33.0087 1.93713 33.0833 2.12499 33.0833H31.875C32.0629 33.0833 32.243 33.0087 32.3759 32.8759C32.5087 32.743 32.5833 32.5629 32.5833 32.375V29.5417C32.5833 29.3538 32.5087 29.1736 32.3759 29.0408C32.243 28.908 32.0629 28.8333 31.875 28.8333Z"
        fill="#111111"
      />
      <path
        id="Vector_2"
        d="M32.2292 10.5102L17.3542 2.01017C17.2472 1.94905 17.1261 1.9169 17.0028 1.9169C16.8796 1.9169 16.7585 1.94905 16.6515 2.01017L1.77649 10.5102C1.66755 10.5717 1.57686 10.6611 1.51366 10.7691C1.45045 10.8771 1.41698 10.9999 1.41666 11.125V14.6667C1.41666 14.8545 1.49128 15.0347 1.62412 15.1675C1.75696 15.3004 1.93713 15.375 2.12499 15.375H31.875C32.0628 15.375 32.243 15.3004 32.3759 15.1675C32.5087 15.0347 32.5833 14.8545 32.5833 14.6667V11.125C32.5836 11.0004 32.551 10.878 32.4888 10.77C32.4266 10.6621 32.337 10.5725 32.2292 10.5102ZM17 11.8333C16.5797 11.8333 16.1689 11.7087 15.8194 11.4752C15.4699 11.2417 15.1976 10.9098 15.0367 10.5215C14.8759 10.1332 14.8338 9.70598 14.9158 9.29377C14.9978 8.88156 15.2002 8.50292 15.4974 8.20574C15.7946 7.90855 16.1732 7.70616 16.5854 7.62417C16.9976 7.54218 17.4249 7.58426 17.8132 7.7451C18.2015 7.90593 18.5334 8.1783 18.7669 8.52775C19.0004 8.87721 19.125 9.28805 19.125 9.70834C19.125 10.2719 18.9011 10.8124 18.5026 11.2109C18.1041 11.6095 17.5636 11.8333 17 11.8333Z"
        fill="#111111"
      />
    </g>
  </svg>
);

const IconGovernment = (props: Props) => {
  return <Icon component={IconCustom} {...props} />;
};

export default IconGovernment;
