import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
type Props = {};

const IconCustom = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="48px_arrow-right">
      <path
        id="Vector"
        d="M0.833252 10.5H18.7499"
        stroke="white"
        stroke-width="1.67832"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_2"
        d="M12.9166 4.66667L18.75 10.5L12.9166 16.3333"
        stroke="white"
        stroke-width="1.67832"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </g>
  </svg>
);

const ArrowRightIcon = (props:  Partial<CustomIconComponentProps>) => {
  return <Icon component={IconCustom} {...props} />;
};

export default ArrowRightIcon;
