import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const IconCustom = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="48px_down-arrow">
      <path
        id="Vector"
        d="M1.16663 4.08334L6.99996 9.91667L12.8333 4.08334"
        stroke="#111111"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </g>
  </svg>
);

const DownArrowIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={IconCustom} {...props} />;
};

export default DownArrowIcon;
