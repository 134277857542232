import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import IconBusiness from "../LDPIcon/IconBusiness";
import ArrowRightIcon from "../LDPIcon/ArrowRightIcon";
import { Col, Layout, Row } from "antd";
import { ReactComponent as Logo } from "../../assets/Logo.svg";
import { ReactComponent as ArrowLeft } from "../../assets/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import IconGovernment from "../LDPIcon/IconGovernment";
import IconSelectList from "../LDPIcon/IconSelectList";
import DownArrowIcon from "../LDPIcon/DownArrowIcon";
import { dataHard, totalHard, totalSub } from "../../data";
import { MyContext } from "../../App";
import { LoginOutlined } from "@ant-design/icons";
import { ReactComponent as Icon24 } from "../../assets/iconSubMenu/24.svg";

type Props = {};
type type = "Government" | "Business";

const { Sider } = Layout;
const LDPSider = (props: Props) => {
  const navigate = useNavigate();
  const divRef: any = useRef(null);

  const { setSider, setIframe, iframe, setToken } = useContext(MyContext);

  const [activeMenu, setActiveMenu] = useState<type>("Government");
  const [activeSubMenu, setActiveSubMenu] = useState<string>("");
  const [typeSubMenu, setTypeSubMenu] = useState<string>("");
  const [heightLogo, setHeightLogo] = useState<number>(116);

  const dataSubmenu = useMemo(() => {
    return Array.from(
      new Set(dataHard.filter((e) => e.type === activeMenu).map((e) => e.page))
    );
  }, [activeMenu]);

  const dataTypeSubMenu = useMemo(() => {
    const arr = Array.from(
      new Set(
        dataHard
          .filter((e) => e.type === activeMenu && e.page === activeSubMenu)
          .map((e) => e.title)
      )
    );

    return arr.every((e) => e) ? arr : [];
  }, [activeMenu, activeSubMenu]);

  useEffect(() => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect();
      setHeightLogo(rect.height || 116);
    }
  }, []);

  useEffect(() => {
    setSider({
      activeMenu,
      activeSubMenu,
      typeSubMenu,
    });
  }, [activeMenu, activeSubMenu, typeSubMenu]);

  const listType: type[] = ["Government", "Business"];

  return (
    <Row
      style={{
        height: "100%",
        borderRight: "20px solid #f9f9f9",
        backgroundColor: "#fff",
      }}
    >
      <Col
        span={24}
        style={{
          padding: "16px",
          backgroundColor: "#fff",
          borderBottom: "1px solid #B1B1B1",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        className="logo-image"
        ref={divRef}
      >
        <Logo onClick={() => navigate("/")} style={{ cursor: "pointer" }} />

        {iframe && (
          <ArrowLeft
            style={{ cursor: "pointer" }}
            onClick={() => setIframe("")}
          />
        )}
      </Col>

      <Col
        xs={6}
        xxl={10}
        style={{
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          {listType.map((type, index) => (
            <div
              title={`${type} (${totalHard
                .filter((e) => e.type === type)
                .reduce((accumulator, currentValue) => {
                  return accumulator + currentValue.total;
                }, 0)})`}
              onClick={() => {
                setActiveMenu(type);
                setActiveSubMenu("");
                setTypeSubMenu("");
              }}
              className={
                activeMenu === type
                  ? "menu-left-sider menu-left-sider-active"
                  : "menu-left-sider"
              }
            >
              {type === "Government" ? <IconGovernment /> : <IconBusiness />}
              <span
                style={{
                  flexGrow: 1,
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 700,
                }}
                className="menu-left-sider__content"
              >
                <p className="hiden_block_1600">{type}</p>
                <p
                  style={{
                    color: "#838383",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 700,
                  }}
                  className="hiden_block_1600"
                >
                  (
                  {totalHard
                    .filter((e) => e.type === type)
                    .reduce((accumulator, currentValue) => {
                      return accumulator + currentValue.total;
                    }, 0)}
                  )
                </p>
              </span>
              <ArrowRightIcon className="hiden_block_1600" />
            </div>
          ))}
        </div>
        <div
          style={{
            padding: "14px",
            // background: "#111",
            color: "#111",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            fontSize: "16px",
            fontWeight: 600,
            cursor: "pointer",
          }}
          onClick={() => {
            setToken("");
            localStorage.removeItem("token");
          }}
        >
          <LoginOutlined />
          <span className="hiden_block_1600">Logout</span>
        </div>
      </Col>
      <Col
        xs={18}
        xxl={14}
        style={{
          borderLeft: "1px solid #B1B1B1",
          overflow: "auto",
          height: `calc(100% - ${heightLogo}px)`,
        }}
      >
        {dataSubmenu.map((e, index) => (
          <div
            key={index}
            style={{
              borderBottom: "1px solid #B1B1B1",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                padding: "20px 26px",
                cursor: "pointer",
                background: e === activeSubMenu ? "#F9F9F9" : "transparent",
              }}
              onClick={() => {
                setActiveSubMenu((el) => (e === el ? "" : e));
                setTypeSubMenu("");
              }}
            >
              <IconSelectList keyIcon={e} />
              <div
                style={{
                  flexGrow: 1,
                  color: "#111",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                }}
              >
                {`${e}`}
                <span
                  style={{
                    color: "#838383",
                  }}
                >
                  {totalHard.find((el) => el.page === e)?.total &&
                    `(${totalHard.find((el) => el.page === e)?.total})`}
                </span>
              </div>

              <DownArrowIcon
                className={
                  e === activeSubMenu
                    ? "menu-right-sider-icon-active "
                    : "menu-right-sider-icon "
                }
              />
            </div>

            {activeSubMenu === e &&
              dataTypeSubMenu.map((e, index) => (
                <div
                  key={index}
                  style={{
                    padding: "20px 26px",
                    cursor: "pointer",
                    background: typeSubMenu === e ? "#DFDFDF" : "#F9F9F9",
                    fontSize: "14px",
                    color: "#111",
                    fontStyle: "normal",
                    fontWeight: 600,
                  }}
                  onClick={() =>
                    setTypeSubMenu((el) => (e === el ? "" : e || ""))
                  }
                >
                  {`${e} `}
                  <span
                    style={{
                      color: "#838383",
                    }}
                  >
                    {totalSub.find((el) => el.title === e)?.total &&
                      `(${totalSub.find((el) => el.title === e)?.total})`}
                  </span>
                </div>
              ))}
          </div>
        ))}
      </Col>
    </Row>
  );
};

export default LDPSider;
