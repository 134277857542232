import { Col, Layout, Row } from "antd";
import React from "react";

import LDPSider from "../LDPSider";
import LDPHeader from "../LDPHeader";

type Props = {
  children: any;
};

const LDPLayout = ({ children }: Props) => {
  return (
    <Layout className="layout">
      <Row
        style={{
          overflow: "hidden",
          height: "100vh",
        }}
      >
        <Col span={24} className="header-mobile">
          <LDPHeader />
        </Col>
        <Col lg={8} style={{ height: "100%" }} className="hiden_block_992">
          <LDPSider />
        </Col>

        <Col xs={24} lg={16} style={{ height: "100%" }}>
          <div
            style={{
              padding: "0px 32px",
              backgroundColor: "#FFF",
              height: "100%",
              overflow: "auto",
              position:"relative"
            }}
          >
            {children}
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default LDPLayout;
