export const dataHard = [
  {
    ID: "G001",
    type: "Government",
    page: "Natural Resources and Environment",
    title: "Ministry of Natural Resources and Environment (Central Level)",
    report:
      "Progress of drafting, adjusting planning/planning, land use plans at all levels",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMDA1NzA3MzgtNzkwZi00MjUwLWEyMzMtYTgzMTBjZTRjMDZiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMDA1NzA3MzgtNzkwZi00MjUwLWEyMzMtYTgzMTBjZTRjMDZiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
    Content:
      'The "Land Use Plans" dashboard is designed to track and monitor the progress of drafting, adjusting, and planning land use strategies at various administrative levels, including provincial and district levels. This dashboard is a crucial tool for the administration to stay informed about the status of land use planning and provides insights into the following key questions:\n\n1. How many provincial and district levels have completed their land use plans?\n2. How many provincial and district levels have not yet implemented their land use plans?\n3. How many provincial and district levels are currently in the process of implementing their land use plans?\n4. What is the progress of changes in land use plans over time?\n\nBy providing answers to these questions, the "Land Use Plans" dashboard serves as a valuable resource for land management authorities, helping them make informed decisions and allocate resources effectively to ensure optimal land use planning and development.',
  },
  {
    ID: "G002",
    type: "Government",
    page: "Natural Resources and Environment",
    title: "Ministry of Natural Resources and Environment (Central Level)",
    report: " \n \t\nSurveying and Mapping Report\n",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMTJhODFlZjQtMmVhMS00YWY5LWExODUtNjRhYzdhYTYwMmNiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection1f6339953e17b17301a4",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMTJhODFlZjQtMmVhMS00YWY5LWExODUtNjRhYzdhYTYwMmNiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection1f6339953e17b17301a4",
    typeLink: "VN",
  },
  {
    ID: "G003",
    type: "Government",
    page: "Natural Resources and Environment",
    title: "Ministry of Natural Resources and Environment (Central Level)",
    report: "Results of implementing the provincial land use plan",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMDNkNzRjZmMtNDk1OC00NWZiLThlM2ItYWE4MjQ4Zjc1ZDkxIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMDNkNzRjZmMtNDk1OC00NWZiLThlM2ItYWE4MjQ4Zjc1ZDkxIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G004",
    type: "Government",
    page: "Natural Resources and Environment",
    title: "Ministry of Natural Resources and Environment (Central Level)",
    report:
      "Results of land allocation, land lease, and land use change permission",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNzZkYWEzNTAtZDBiNy00NmZjLThjYTgtYjYyOThkMDcyNTUzIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNzZkYWEzNTAtZDBiNy00NmZjLThjYTgtYjYyOThkMDcyNTUzIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G005",
    type: "Government",
    page: "Natural Resources and Environment",
    title: "Ministry of Natural Resources and Environment (Central Level)",
    report: "Summary of land revenue sources",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZjkzM2U4NTUtNjI2OC00YjU0LWFlODUtNTYwNzIwODgwNWZiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZjkzM2U4NTUtNjI2OC00YjU0LWFlODUtNTYwNzIwODgwNWZiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G006",
    type: "Government",
    page: "Natural Resources and Environment",
    title: "Ministry of Natural Resources and Environment (Central Level)",
    report:
      "Results of land acquisition, compensation, support, and resettlement",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYTQ1Y2VjNWItZGQ3Zi00MzI5LWExZWUtMTRkMTJhZjFhZDBjIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection1a51d2f5f93cc418205c",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYTQ1Y2VjNWItZGQ3Zi00MzI5LWExZWUtMTRkMTJhZjFhZDBjIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection1a51d2f5f93cc418205c",
    typeLink: "VN",
  },
  {
    ID: "G007",
    type: "Government",
    page: "Natural Resources and Environment",
    title: "Ministry of Natural Resources and Environment (Central Level)",
    report:
      "Summary of the results of cadastral mapping, registration, certificate issuance, and the establishment of a land database",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYWExMDQyN2ItNTU4Mi00NmM5LWJhNmUtNDRiNTczOTY5MTFhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYWExMDQyN2ItNTU4Mi00NmM5LWJhNmUtNDRiNTczOTY5MTFhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G008",
    type: "Government",
    page: "Natural Resources and Environment",
    title: "Ministry of Natural Resources and Environment (Central Level)",
    report: "Results of resolving land disputes, complaints, and denunciations",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNWM3YjBjNTUtZjZhOS00Mzk2LTg4MmItY2JjMzJjYWY4MWEyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionc6388eb16ac75ee13595",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNWM3YjBjNTUtZjZhOS00Mzk2LTg4MmItY2JjMzJjYWY4MWEyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionc6388eb16ac75ee13595",
    typeLink: "VN",
  },
  {
    ID: "G009",
    type: "Government",
    page: "Natural Resources and Environment",
    title: "Ministry of Natural Resources and Environment (Central Level)",
    report: "Statistics on the results of exercising the rights of land users",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNTk5ZjFkNGEtOTVhOS00Yzc1LWFlMzEtNTBjMGNmZmY1MWI0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNTk5ZjFkNGEtOTVhOS00Yzc1LWFlMzEtNTBjMGNmZmY1MWI0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G010",
    type: "Government",
    page: "Natural Resources and Environment",
    title: "Department of Natural Resources and Environment (Provincial Level)",
    report: "Land sector report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZDc0OWFlNjMtMmZiZC00NWUyLWE5MDYtYmM1ZjliMzdlYWZlIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection946d7f20ae1e31711d76",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZDc0OWFlNjMtMmZiZC00NWUyLWE5MDYtYmM1ZjliMzdlYWZlIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection946d7f20ae1e31711d76",
    typeLink: "VN",
  },
  {
    ID: "G011",
    type: "Government",
    page: "Natural Resources and Environment",
    title: "Department of Natural Resources and Environment (Provincial Level)",
    report: "Surveying and mapping report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNTJjNDExOTEtYzZjMi00ZjlhLWEzMzQtNWVmZmI1YTc1Y2EzIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection6d64c7e00dc0d209a669",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNTJjNDExOTEtYzZjMi00ZjlhLWEzMzQtNWVmZmI1YTc1Y2EzIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection6d64c7e00dc0d209a669",
    typeLink: "VN",
  },
  {
    ID: "G012",
    type: "Government",
    page: "Natural Resources and Environment",
    title: "Department of Natural Resources and Environment (Provincial Level)",
    report: "Mineral sector report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNzk2NDJlZWUtMTNiZC00OGM4LWFjMzMtYTVjNzMxOWUzZTg3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectiona73dd88b0c6a0f71ed66",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNzk2NDJlZWUtMTNiZC00OGM4LWFjMzMtYTVjNzMxOWUzZTg3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectiona73dd88b0c6a0f71ed66",
    typeLink: "VN",
  },
  {
    ID: "G013",
    type: "Government",
    page: "Natural Resources and Environment",
    title: "Department of Natural Resources and Environment (Provincial Level)",
    report: "Water resources report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNDc2NDdjZTktNmVlZS00MDc2LWJiNjQtMDQwNmM5ZWMyMWY2IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection317fe28e2ccb230ba3a5",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNDc2NDdjZTktNmVlZS00MDc2LWJiNjQtMDQwNmM5ZWMyMWY2IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection317fe28e2ccb230ba3a5",
    typeLink: "VN",
  },
  {
    ID: "G014",
    type: "Government",
    page: "Natural Resources and Environment",
    title: "Department of Natural Resources and Environment (Provincial Level)",
    report: "Environmental sector report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZmM4OWY2YjMtMWIwZi00NjFmLWFkN2ItZGM0MDBmNmQ2ZTQxIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectiond38b5447a08627f93ef6",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZmM4OWY2YjMtMWIwZi00NjFmLWFkN2ItZGM0MDBmNmQ2ZTQxIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectiond38b5447a08627f93ef6",
    typeLink: "VN",
  },
  {
    ID: "G015",
    type: "Government",
    page: "Education and Training",
    title: "Department of Education and Training (Provincial Level)",
    report: "Statistics of education-specific indicators",
    link: "https://app.powerbi.com/view?r=eyJrIjoiOTUyZWJhOWItZmE0Yy00NTZjLWJmZjItNzBmNDdiOTM3MmNhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionc380484045c98252aa60",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiOTUyZWJhOWItZmE0Yy00NTZjLWJmZjItNzBmNDdiOTM3MmNhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionc380484045c98252aa60",
    typeLink: "EN",
    Content:
      'The "Education-specific Indicators" dashboard is a comprehensive tool for monitoring key KPIs in the education sector. It provides insights into various aspects of education, and its multipage layout addresses specific questions:\n\n1. How do key school indicators, such as the number of schools, classes, average class size, and student-teacher ratio, vary by region, and where are the highest student-teacher ratios and average class sizes located?\n2. What is the percentage of schools and classes that meet the qualifications for standard levels 1 and 2?\n3. What is the composition of students in terms of the proportion of females, policy-support students, and trends in student dropouts over time?\n4. What are the characteristics of the teacher workforce, including their qualifications, age distribution, regional distribution, and teaching experience?',
  },
  {
    ID: "G016",
    type: "Government",
    page: "Education and Training",
    title: "Ministry of Education and Training (Central Level)",
    report: "Statistics for all levels of education",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYzM2NzgxY2EtMTk4NS00MTA3LWE2NmYtMTY3YTFlMWI1ZGFiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYzM2NzgxY2EtMTk4NS00MTA3LWE2NmYtMTY3YTFlMWI1ZGFiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G017",
    type: "Government",
    page: "Education and Training",
    title: "Ministry of Education and Training (Central Level)",
    report: "Kindergarten",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMGZhZGI4ZmItNzI4YS00OGFlLTkxYTctZTkwYWE2MDA3Y2NjIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMGZhZGI4ZmItNzI4YS00OGFlLTkxYTctZTkwYWE2MDA3Y2NjIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G018",
    type: "Government",
    page: "Education and Training",
    title: "Ministry of Education and Training (Central Level)",
    report: "Primary school",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMWU5NDk4MzctNDI1MC00NjA5LTgyOWItOWU1ZGY1Njc2MzJkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMWU5NDk4MzctNDI1MC00NjA5LTgyOWItOWU1ZGY1Njc2MzJkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G019",
    type: "Government",
    page: "Education and Training",
    title: "Ministry of Education and Training (Central Level)",
    report: "Junior high school",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYTJlZTE0NDItNDcxMi00ZTc1LTg5ZmMtNjA5NTYwNjk0NDk5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYTJlZTE0NDItNDcxMi00ZTc1LTg5ZmMtNjA5NTYwNjk0NDk5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G020",
    type: "Government",
    page: "Education and Training",
    title: "Ministry of Education and Training (Central Level)",
    report: "High school",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNjg0YmYyOTktNzUxOC00ZTVhLWFiYTItYTUxZTU4NjMxMGY0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNjg0YmYyOTktNzUxOC00ZTVhLWFiYTItYTUxZTU4NjMxMGY0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G021",
    type: "Government",
    page: "Education and Training",
    title: "Ministry of Education and Training (Central Level)",
    report: "Continuing education",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZGE3ZjMxNGUtYzY5YS00NmQyLTg2NmEtYzBhN2I4YmE3ZTZmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZGE3ZjMxNGUtYzY5YS00NmQyLTg2NmEtYzBhN2I4YmE3ZTZmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G022",
    type: "Government",
    page: "Education and Training",
    title: "Department of Education and Training (Provincial Level)",
    report:
      "Statistical report on the results of education in primary school subjects at the end of the school year",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNjAwZTNjZmItNTYzNi00NmNlLWJmYmUtMDQ1MjdhODQwMGZmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNjAwZTNjZmItNTYzNi00NmNlLWJmYmUtMDQ1MjdhODQwMGZmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G023",
    type: "Government",
    page: "Education and Training",
    title: "Department of Education and Training (Provincial Level)",
    report:
      "Statistical report on the classification of each ability and quality at the end of the school year",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYzk2MDdkMDYtMzNlYi00MzdiLTgyOGQtNWZjY2U5YTE4YWU5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYzk2MDdkMDYtMzNlYi00MzdiLTgyOGQtNWZjY2U5YTE4YWU5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G024",
    type: "Government",
    page: "Education and Training",
    title: "Department of Education and Training (Provincial Level)",
    report:
      "Report on the results of regular tests in Vietnamese and math at the end of the school year",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZmY3YjNlNGUtODUzYi00ZDQwLWI1Y2QtOWQzOGQ0NGVhZGFlIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZmY3YjNlNGUtODUzYi00ZDQwLWI1Y2QtOWQzOGQ0NGVhZGFlIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G025",
    type: "Government",
    page: "Education and Training",
    title: "Department of Education and Training (Provincial Level)",
    report:
      "Statistical report on the quality of education for primary school students at the end of the school year",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMDBjN2U2MjUtMTdkOC00YmY3LWExN2QtYjQ5NjU0ODM4MTE3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMDBjN2U2MjUtMTdkOC00YmY3LWExN2QtYjQ5NjU0ODM4MTE3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    typeLink: "VN",
  },
  {
    ID: "G026",
    type: "Government",
    page: "Education and Training",
    title: "Department of Education and Training (Provincial Level)",
    report:
      "Principal's report on the results of teacher professional standards assessment",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNzhlN2Y5NWYtYzVmZi00MWYxLWEzMjctMTE3YzIyMDJjMjI3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionf6ed59169927231076ca",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNzhlN2Y5NWYtYzVmZi00MWYxLWEzMjctMTE3YzIyMDJjMjI3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionf6ed59169927231076ca",
    typeLink: "VN",
  },
  {
    ID: "G027",
    type: "Government",
    page: "Education and Training",
    title: "Department of Education and Training (Provincial Level)",
    report: "Report on student affairs",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZjA5MmJlZjYtZTNkZS00Yjg0LWFkNDItZWNlNDUyZGZkNzcyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZjA5MmJlZjYtZTNkZS00Yjg0LWFkNDItZWNlNDUyZGZkNzcyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G028",
    type: "Government",
    page: "Education and Training",
    title: "Department of Education and Training (Provincial Level)",
    report: "Report on state budget expenditures",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYzgxNmZiNTktMTM3MC00ZjZmLTkzMWUtOTA0MmY2NTExY2VjIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYzgxNmZiNTktMTM3MC00ZjZmLTkzMWUtOTA0MmY2NTExY2VjIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G029",
    type: "Government",
    page: "Education and Training",
    title: "Department of Education and Training (Provincial Level)",
    report: "Report on inspection and audit",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNTUzMTlhMWEtZTgxYi00MjlhLThmMjUtNTMzOTcyMWUyMTM2IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNTUzMTlhMWEtZTgxYi00MjlhLThmMjUtNTMzOTcyMWUyMTM2IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G030",
    type: "Government",
    page: "Education and Training",
    title: "Department of Education and Training (Provincial Level)",
    report:
      "Report on the results of the implementation of Red Cross activities; security in education",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZmEzMzc1ZDktMzMyMy00Mjg1LTk3YzYtYWFjMjY0ZmJiZjQxIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZmEzMzc1ZDktMzMyMy00Mjg1LTk3YzYtYWFjMjY0ZmJiZjQxIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G031",
    type: "Government",
    page: "Education and Training",
    title: "Department of Education and Training (Provincial Level)",
    report:
      "Report on the implementation of science and technology plans by units under the Ministry",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMTE2YWFkMDktNmVmNC00NmMyLWFiZmYtMmZmZmFjM2JjYWNmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMTE2YWFkMDktNmVmNC00NmMyLWFiZmYtMmZmZmFjM2JjYWNmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G032",
    type: "Government",
    page: "Education and Training",
    title: "Department of Education and Training (Provincial Level)",
    report: "Report on the management of diplomas from Vietnam and abroad",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNGRiOTYyZDktYjE4Yi00NjFlLWIxNDQtOTdiMGEzMmFmMWExIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNGRiOTYyZDktYjE4Yi00NjFlLWIxNDQtOTdiMGEzMmFmMWExIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G033",
    type: "Government",
    page: "Education and Training",
    title: "Department of Education and Training (Provincial Level)",
    report:
      "Report on the status and results of activities for organizing IT certification exams; training, testing, and issuing foreign language certificates",
    link: "https://app.powerbi.com/view?r=eyJrIjoiOWY0NWVjODAtZDYwNC00MjZlLWI0YzUtMWY5OTA1YzQ0ZGVhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiOWY0NWVjODAtZDYwNC00MjZlLWI0YzUtMWY5OTA1YzQ0ZGVhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G034",
    type: "Government",
    page: "Education and Training",
    title: "Department of Education and Training (Provincial Level)",
    report:
      "Report on the results of the work of educational quality assurance and recognition of schools meeting national standards",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNzYxMGE1NGItMWZjZS00ZTc1LWI0NTYtZDdlM2Y3NmE2ZjY4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectioncc90bb1b3652c9242344",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNzYxMGE1NGItMWZjZS00ZTc1LWI0NTYtZDdlM2Y3NmE2ZjY4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectioncc90bb1b3652c9242344",
    typeLink: "VN",
  },
  {
    ID: "G035",
    type: "Government",
    page: "Education and Training",
    title: "Department of Education and Training (Provincial Level)",
    report:
      "Report on the status and results of the work of organizing exams and assessing foreign language proficiency according to the Vietnamese 6-level foreign language proficiency framework",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNDA1NjQ3OGYtMmU1NS00YmExLWIzNmUtMDQwNTVlMDNmZmFhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNDA1NjQ3OGYtMmU1NS00YmExLWIzNmUtMDQwNTVlMDNmZmFhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G036",
    type: "Government",
    page: "Education and Training",
    title: "Department of Education and Training (Provincial Level)",
    report:
      "Report on the situation of foreign cooperation and investment in the field of education",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYWY5ZjYzNzAtMjVhMy00ZDMyLTllODItNjEyZDVmOTFhYjU5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYWY5ZjYzNzAtMjVhMy00ZDMyLTllODItNjEyZDVmOTFhYjU5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G037",
    type: "Government",
    page: "Education and Training",
    title: "Department of Education and Training (Provincial Level)",
    report:
      "Report on the results of international cooperation in the field of education and training",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYzAzZGQ2N2EtZWVkMS00MjZiLTk0ODktMDE1MDc2ZWVhM2E3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYzAzZGQ2N2EtZWVkMS00MjZiLTk0ODktMDE1MDc2ZWVhM2E3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G038",
    type: "Government",
    page: "Education and Training",
    title: "Department of Education and Training (Provincial Level)",
    report: "Special education",
    link: "https://app.powerbi.com/view?r=eyJrIjoiOTAyZWYzODYtMzk2NC00OGFiLWE3YmMtNzEzZWNkNmUzM2U0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection83f00718381826d7c5a0",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiOTAyZWYzODYtMzk2NC00OGFiLWE3YmMtNzEzZWNkNmUzM2U0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection83f00718381826d7c5a0",
    typeLink: "VN",
  },
  {
    ID: "G039",
    type: "Government",
    page: "Education and Training",
    title: "Department of Education and Training (Provincial Level)",
    report:
      'Reports on the management of the state for the business of consulting services for overseas study"',
    link: "https://app.powerbi.com/view?r=eyJrIjoiZjQ1ODdhNjMtYzgxZi00ODJkLTliYTUtN2U3YTlmYjBhMWQxIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZjQ1ODdhNjMtYzgxZi00ODJkLTliYTUtN2U3YTlmYjBhMWQxIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    typeLink: "VN",
  },
  {
    ID: "G040",
    type: "Government",
    page: "Transportation",
    title: "Ministry of Transport (Central Level)",
    report: "Passenger transportation - public bus ",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYjFmNDBjMjAtOTYyNC00ODFmLTk1ODQtZGE1NmViMmNiNzdkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYjFmNDBjMjAtOTYyNC00ODFmLTk1ODQtZGE1NmViMmNiNzdkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
    Content:
      'The "Passenger Transportation (Public Bus in Year 2019)" dashboard offers essential insights into the usage of buses in major cities across Vietnam. This valuable tool simplifies administration by addressing key questions:\n\n1. How many buses are currently in operation, and what is the size of the bus fleet? Additionally, what are the primary bus routes in major cities?\n2. Which are the top five cities with the most developed bus networks in Vietnam?\n3. What is the percentage of buses that are equipped to support people with disabilities, and how many are using clean fuel technology?\n\nFurthermore, this dashboard features an interactive Vietnamese map on the left, allowing users to access dynamic information for each province simply by making selections. This functionality enhances the ability to monitor and analyze passenger transportation trends across the country.',
  },
  {
    ID: "G041",
    type: "Government",
    page: "Transportation",
    title: "Ministry of Transport (Central Level)",
    report:
      "The implementation status of basic investment construction projects directly managed by the Ministry of Transport",
    link: "http://bit.ly/BC09T_GTVT",
    hyperLink: "http://bit.ly/BC09T_GTVT",
    typeLink: "VN",
  },
  {
    ID: "G042",
    type: "Government",
    page: "Transportation",
    title: "Ministry of Transport (Central Level)",
    report: "The number of cars in circulation",
    link: "http://bit.ly/BC15N_GTVT",
    hyperLink: "http://bit.ly/BC15N_GTVT",
    typeLink: "VN",
  },
  {
    ID: "G043",
    type: "Government",
    page: "Transportation",
    title: "Ministry of Transport (Central Level)",
    report: "Traffic violation handling situation",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNWUyNDBjMDctNDk2ZC00MGE4LTk3YjgtYzk1NjhjNjRjMzNjIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection7a959f8490a8a444b708",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNWUyNDBjMDctNDk2ZC00MGE4LTk3YjgtYzk1NjhjNjRjMzNjIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection7a959f8490a8a444b708",
    typeLink: "VN",
  },
  {
    ID: "G044",
    type: "Government",
    page: "Transportation",
    title: "Ministry of Transport (Central Level)",
    report:
      "The number of inland waterway vehicles participating in traffic classified by purpose and size",
    link: "http://bit.ly/BC18N_GTVT",
    hyperLink: "http://bit.ly/BC18N_GTVT",
    typeLink: "VN",
  },
  {
    ID: "G045",
    type: "Government",
    page: "Transportation",
    title: "Ministry of Transport (Central Level)",
    report:
      "The number of cars, motorbikes, and mopeds participating in traffic",
    link: "http://bit.ly/BC19N_GTVT",
    hyperLink: "http://bit.ly/BC19N_GTVT",
    typeLink: "VN",
  },
  {
    ID: "G046",
    type: "Government",
    page: "Transportation",
    title: "Ministry of Transport (Central Level)",
    report: "The volume of goods through ports",
    link: "https://bitly.com.vn/jixz1d",
    hyperLink: "https://bitly.com.vn/jixz1d",
    typeLink: "VN",
  },
  {
    ID: "G047",
    type: "Government",
    page: "Transportation",
    title: "Ministry of Transport (Central Level)",
    report: "Revenue and output of commercial aviation technical services",
    link: "http://bit.ly/BC31Q_GTVT",
    hyperLink: "http://bit.ly/BC31Q_GTVT",
    typeLink: "VN",
  },
  {
    ID: "G048",
    type: "Government",
    page: "Transportation",
    title: "Ministry of Transport (Central Level)",
    report:
      "The output of transportation services, enterprises and supporting activities for rail, road, inland waterway and sea transportation",
    link: "http://bit.ly/BC33N_GTVT",
    hyperLink: "http://bit.ly/BC33N_GTVT",
    typeLink: "VN",
  },
  {
    ID: "G049",
    type: "Government",
    page: "Transportation",
    title: "Ministry of Transport (Central Level)",
    report: "The situation of traffic accidents in the whole country",
    link: "https://bit.ly/2WBDSjd",
    hyperLink: "https://bit.ly/2WBDSjd",
    typeLink: "VN",
  },
  {
    ID: "G050",
    type: "Government",
    page: "Transportation",
    title: "Ministry of Transport (Central Level)",
    report:
      "List of roads managed by the central government classified by technical level and road surface structure",
    link: "http://bit.ly/BC42_5N_GTVT",
    hyperLink: "http://bit.ly/BC42_5N_GTVT",
    typeLink: "VN",
  },
  {
    ID: "G051",
    type: "Government",
    page: "Transportation",
    title: "Ministry of Transport (Central Level)",
    report: "List of bridges and roads managed by the central government",
    link: "http://bit.ly/BC43_5N_GTVT",
    hyperLink: "http://bit.ly/BC43_5N_GTVT",
    typeLink: "VN",
  },
  {
    ID: "G052",
    type: "Government",
    page: "Transportation",
    title: "Ministry of Transport (Central Level)",
    report: "Department of Transportation Construction Quality Management",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYTdlMTRmZjktZmE4ZS00Y2JhLTg4YWItNDQ5Mzg2NTM4ZjQzIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection94a192c68d0d02ec10c0",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYTdlMTRmZjktZmE4ZS00Y2JhLTg4YWItNDQ5Mzg2NTM4ZjQzIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection94a192c68d0d02ec10c0",
    typeLink: "VN",
  },
  {
    ID: "G053",
    type: "Government",
    page: "Transportation",
    title: "Ministry of Transport (Central Level)",
    report: "Department of Driver Training and Testing",
    link: "https://app.powerbi.com/view?r=eyJrIjoiODk3ZTMzNDctMGU3OS00MGM0LTllZWItYzAwMjUxNTQzZDZhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionf60de52104a00a0b8b45",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiODk3ZTMzNDctMGU3OS00MGM0LTllZWItYzAwMjUxNTQzZDZhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionf60de52104a00a0b8b45",
    typeLink: "VN",
  },
  {
    ID: "G054",
    type: "Government",
    page: "Transportation",
    title: "Ministry of Transport (Central Level)",
    report: "List of inland waterway routes in operation",
    link: "http://bit.ly/BC46_5N_GTVT",
    hyperLink: "http://bit.ly/BC46_5N_GTVT",
    typeLink: "VN",
  },
  {
    ID: "G055",
    type: "Government",
    page: "Transportation",
    title: "Ministry of Transport (Central Level)",
    report:
      "List of inland waterway routes managed by local governments in operation",
    link: "http://bit.ly/BC47_5N_GTVT",
    hyperLink: "http://bit.ly/BC47_5N_GTVT",
    typeLink: "VN",
  },
  {
    ID: "G056",
    type: "Government",
    page: "Transportation",
    title: "Department of Transport (provincial level)",
    report:
      "Report of the Department of Infrastructure and Traffic Safety Management",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNWUyNDBjMDctNDk2ZC00MGE4LTk3YjgtYzk1NjhjNjRjMzNjIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection7a959f8490a8a444b708",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNWUyNDBjMDctNDk2ZC00MGE4LTk3YjgtYzk1NjhjNjRjMzNjIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection7a959f8490a8a444b708",
    typeLink: "VN",
  },
  {
    ID: "G057",
    type: "Government",
    page: "Transportation",
    title: "Department of Transport (provincial level)",
    report: "Report of the Department of Planning and Finance",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYjI3ZTRlZWQtMmY1OS00NTMwLThmYjUtNTVlOWFjMzc2OTFlIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYjI3ZTRlZWQtMmY1OS00NTMwLThmYjUtNTVlOWFjMzc2OTFlIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G058",
    type: "Government",
    page: "Transportation",
    title: "Department of Transport (provincial level)",
    report:
      "Report of the Department of Traffic Construction Quality Management",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYTdlMTRmZjktZmE4ZS00Y2JhLTg4YWItNDQ5Mzg2NTM4ZjQzIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection94a192c68d0d02ec10c0",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYTdlMTRmZjktZmE4ZS00Y2JhLTg4YWItNDQ5Mzg2NTM4ZjQzIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection94a192c68d0d02ec10c0",
    typeLink: "VN",
  },
  {
    ID: "G059",
    type: "Government",
    page: "Transportation",
    title: "Department of Transport (provincial level)",
    report: "Report of the Department of Driver Training and Testing",
    link: "https://app.powerbi.com/view?r=eyJrIjoiODk3ZTMzNDctMGU3OS00MGM0LTllZWItYzAwMjUxNTQzZDZhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiODk3ZTMzNDctMGU3OS00MGM0LTllZWItYzAwMjUxNTQzZDZhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G060",
    type: "Government",
    page: "Transportation",
    title: "Department of Transport (provincial level)",
    report: "Report of the Department Office",
    link: "https://app.powerbi.com/view?r=eyJrIjoiN2Y4NDlkM2UtZDEzNC00MTc4LTk1M2UtZWRjYjQ1MzFlNjJlIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection8eddd65f21b89771e801",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiN2Y4NDlkM2UtZDEzNC00MTc4LTk1M2UtZWRjYjQ1MzFlNjJlIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection8eddd65f21b89771e801",
    typeLink: "VN",
  },
  {
    ID: "G061",
    type: "Government",
    page: "Transportation",
    title: "Department of Transport (provincial level)",
    report: "Report of the Department of Transportation Management",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYmFhNmRkNzYtYTI4Ni00YzVhLWI5YWYtOTFkZGI3OTFiNDlmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYmFhNmRkNzYtYTI4Ni00YzVhLWI5YWYtOTFkZGI3OTFiNDlmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G062",
    type: "Government",
    page: "Planning and Investment",
    title: "Department of Planning and Investment (Provincial Level)",
    report: "Summary of the business registration situation",
    link: "https://app.powerbi.com/view?r=eyJrIjoiM2ExYTQzMjAtNmNjZS00MmZiLThmNjEtYWVlMDZhNGJiYmUzIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiM2ExYTQzMjAtNmNjZS00MmZiLThmNjEtYWVlMDZhNGJiYmUzIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
    Content:
      'The "Summary of Business Registration Situation" dashboard provides the administration with a comprehensive overview of the current status of business registrations over time, including various location dimensions. This dashboard delivers detailed information on:\n\n1. What is the total number of active businesses, and how has the amount of registered capital changed over the years?\n2. How many businesses have dissolved or been suspended over time?\n3. Can you provide detailed information on the number of new businesses registered by industry?\n4. What are the location-specific details, including major KPIs for registered businesses and dissolved businesses?\n\nThis dashboard serves as a vital tool for monitoring the business landscape, offering insights into business registration trends, industry performance, and regional variations, enabling informed decision-making for administrators.',
  },
  {
    ID: "G063",
    type: "Government",
    page: "Planning and Investment",
    title: "Department of Planning and Investment (Provincial Level)",
    report:
      "Report on the situation of capital disbursement for public investment",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZjkzZTAxNTgtMzM3OC00OTRjLThjMjctY2Q1ZThhMDg4NDZmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZjkzZTAxNTgtMzM3OC00OTRjLThjMjctY2Q1ZThhMDg4NDZmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G064",
    type: "Government",
    page: "Planning and Investment",
    title: "Department of Planning and Investment (Provincial Level)",
    report: "Report on the situation of domestic investment",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYzljNzI4OWEtZjg2Zi00ZWMzLWE5NTctNGIyZmJkMTNiYjNhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYzljNzI4OWEtZjg2Zi00ZWMzLWE5NTctNGIyZmJkMTNiYjNhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    typeLink: "VN",
  },
  {
    ID: "G065",
    type: "Government",
    page: "Planning and Investment",
    title: "Department of Planning and Investment (Provincial Level)",
    report: "Economic Indicators Report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMzljZGNhZmMtZTVmNi00ZTc0LTliZmEtODIwODlkZmMxNjdmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMzljZGNhZmMtZTVmNi00ZTc0LTliZmEtODIwODlkZmMxNjdmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G066",
    type: "Government",
    page: "Planning and Investment",
    title: "Department of Planning and Investment (Provincial Level)",
    report: "Industry, Agriculture, Services, and Trade Indicators Report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZjM5MWM4NzUtMmZkMi00YTVmLTliZmItNjEzY2U4MTg2MmFhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZjM5MWM4NzUtMmZkMi00YTVmLTliZmItNjEzY2U4MTg2MmFhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    typeLink: "VN",
  },
  {
    ID: "G067",
    type: "Government",
    page: "Planning and Investment",
    title: "Department of Planning and Investment (Provincial Level)",
    report: "Public investment",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZTQ0NWE4NWEtYjYwYy00MWVjLTlmZmQtOGQ5ZTQ5MGM2Y2YyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZTQ0NWE4NWEtYjYwYy00MWVjLTlmZmQtOGQ5ZTQ5MGM2Y2YyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G068",
    type: "Government",
    page: "Planning and Investment",
    title: "Department of Planning and Investment (Provincial Level)",
    report: "Environmental Indicators Report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZDg0ZmFiYzktZDFjNC00OTRlLWI3ODgtNjU1ZDY1NmM0NjE3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZDg0ZmFiYzktZDFjNC00OTRlLWI3ODgtNjU1ZDY1NmM0NjE3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G069",
    type: "Government",
    page: "Planning and Investment",
    title: "Department of Planning and Investment (Provincial Level)",
    report: "Education Indicators Report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiODg1NjExNGMtNDJmZS00MmQyLWE0MmItNTkxMmYxZDhkZGI4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiODg1NjExNGMtNDJmZS00MmQyLWE0MmItNTkxMmYxZDhkZGI4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G070",
    type: "Government",
    page: "Planning and Investment",
    title: "Department of Planning and Investment (Provincial Level)",
    report:
      "Culture, Sports, Information and Communication, and Broadcasting and Television Indicators Report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMTlmZGJhY2YtM2JlZi00M2FmLTgwMzctNWVlMDU1NDEyYjVhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMTlmZGJhY2YtM2JlZi00M2FmLTgwMzctNWVlMDU1NDEyYjVhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G071",
    type: "Government",
    page: "Planning and Investment",
    title: "Department of Planning and Investment (Provincial Level)",
    report: "Construction and Transportation Indicators Report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNTdkZThmYmMtZDJmMi00MjEwLTg1MDItMGZiM2I5ZTgzODI5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNTdkZThmYmMtZDJmMi00MjEwLTg1MDItMGZiM2I5ZTgzODI5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G072",
    type: "Government",
    page: "Finance",
    title: "Ministry of Finance (Central Level)",
    report: "The implementation status of government budget balance",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYzIzYjQ2MzMtOWQ0OC00YmNmLWEyN2YtZWQyMGM2YTM0YjQ5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYzIzYjQ2MzMtOWQ0OC00YmNmLWEyN2YtZWQyMGM2YTM0YjQ5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
    Content:
      "The \"State Budget Revenue Performance\" dashboard offers the administration a comprehensive overview of the state's revenue, expenditure, and principal payments over time. This dashboard provides insights into:\n\n1. How has the structure of the revenue budget evolved over time, including the sources of revenue and which sources are showing growth?\n2. A comprehensive comparison of changes in the state's revenue budget from month to month and the same period in the previous year.\n3. How has the structure of the expenditure budget changed over time, with details on the sources of expenditure and which sources are increasing.\n4. A comprehensive comparison of changes in the state's expenditure budget from month to month and the same period in the previous year.\n\nThis dashboard is a valuable tool for monitoring the financial performance of the state, allowing administrators to make informed decisions based on revenue and expenditure trends.",
  },
  {
    ID: "G073",
    type: "Government",
    page: "Finance",
    title: "Ministry of Finance (Central Level)",
    report: "Government budget revenue and revenue structure",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNjg1NDAwYzUtNTVkNC00YzAxLWE4OGMtMGVhN2EwNzhkMGVjIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionb2b6409394975c8cd755",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNjg1NDAwYzUtNTVkNC00YzAxLWE4OGMtMGVhN2EwNzhkMGVjIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionb2b6409394975c8cd755",
    typeLink: "VN",
  },
  {
    ID: "G074",
    type: "Government",
    page: "Finance",
    title: "Ministry of Finance (Central Level)",
    report: "Government budget revenue by industry and economic sector",
    link: "https://app.powerbi.com/view?r=eyJrIjoiM2JkMGNmMWUtMjkxNC00YTczLTllYzItNjdkOGVkNzA1YWM1IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionb2b6409394975c8cd755",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiM2JkMGNmMWUtMjkxNC00YTczLTllYzItNjdkOGVkNzA1YWM1IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionb2b6409394975c8cd755",
    typeLink: "VN",
  },
  {
    ID: "G075",
    type: "Government",
    page: "Finance",
    title: "Ministry of Finance (Central Level)",
    report: "Revenue and expenditure of the state treasury",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZDY3YmQ3OWItMWI5MC00ZTNlLWIzN2QtNmFlMTc5ODFlY2U1IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection03f054e365d1b8532720",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZDY3YmQ3OWItMWI5MC00ZTNlLWIzN2QtNmFlMTc5ODFlY2U1IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection03f054e365d1b8532720",
    typeLink: "VN",
  },
  {
    ID: "G076",
    type: "Government",
    page: "Finance",
    title: "Ministry of Finance (Central Level)",
    report: "Government borrowing and debt repayment",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNjUyNjRiNzktNWY1ZC00OTE2LTkxNGItM2UyZmUwOTdiNWVhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNjUyNjRiNzktNWY1ZC00OTE2LTkxNGItM2UyZmUwOTdiNWVhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    typeLink: "VN",
  },
  {
    ID: "G077",
    type: "Government",
    page: "Finance",
    title: "Ministry of Finance (Central Level)",
    report: "Sources of formation of the national reserve",
    link: "https://app.powerbi.com/view?r=eyJrIjoiODllMTdlOTItYmE2OC00NWM1LWEzMzMtNjc2ODhlYjIzMTA5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiODllMTdlOTItYmE2OC00NWM1LWEzMzMtNjc2ODhlYjIzMTA5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    typeLink: "VN",
  },
  {
    ID: "G078",
    type: "Government",
    page: "Finance",
    title: "Ministry of Finance (Central Level)",
    report: "The situation of trading on the stock market",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZDkzZjM5NTQtMTAxZS00ZjIzLWFiNDUtZTQxOTQ4ZjFiZWFhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZDkzZjM5NTQtMTAxZS00ZjIzLWFiNDUtZTQxOTQ4ZjFiZWFhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    typeLink: "VN",
  },
  {
    ID: "G079",
    type: "Government",
    page: "Finance",
    title: "Ministry of Finance (Central Level)",
    report: "The scale of the stock market",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNDAxMDc2NDktZjE3Ny00MDcwLTllYzItZGUyOWEzNjE3OWZhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNDAxMDc2NDktZjE3Ny00MDcwLTllYzItZGUyOWEzNjE3OWZhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G080",
    type: "Government",
    page: "Finance",
    title: "Ministry of Finance (Central Level)",
    report: "The situation of government bonds",
    link: "https://app.powerbi.com/view?r=eyJrIjoiODkxZGI1MzItNDBhZi00OWU1LThmZWYtMmY2ZjMyNmYxOGZmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiODkxZGI1MzItNDBhZi00OWU1LThmZWYtMmY2ZjMyNmYxOGZmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    typeLink: "VN",
  },
  {
    ID: "G081",
    type: "Government",
    page: "Finance",
    title: "Ministry of Finance (Central Level)",
    report: "The situation of corporate bonds",
    link: "https://app.powerbi.com/view?r=eyJrIjoiODkxZGI1MzItNDBhZi00OWU1LThmZWYtMmY2ZjMyNmYxOGZmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection6563da22a33a55b8365b",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiODkxZGI1MzItNDBhZi00OWU1LThmZWYtMmY2ZjMyNmYxOGZmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection6563da22a33a55b8365b",
    typeLink: "VN",
  },
  {
    ID: "G082",
    type: "Government",
    page: "Finance",
    title: "Department of Finance (Provincial Level)",
    report: "Report on the implementation of the balance of local budgets",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYzk1MTE5MTgtOTM5MS00ZDM5LTg4N2EtYjMwYWFjZTNjNWQ4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYzk1MTE5MTgtOTM5MS00ZDM5LTg4N2EtYjMwYWFjZTNjNWQ4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G083",
    type: "Government",
    page: "Finance",
    title: "Department of Finance (Provincial Level)",
    report:
      "Consolidated report on revenue and expenditure of provincial, district, and commune budgets",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZWE4ZTJmYTEtZDE1YS00OWQ3LTkxZDctYjI4NWFkNzg0MzRkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZWE4ZTJmYTEtZDE1YS00OWQ3LTkxZDctYjI4NWFkNzg0MzRkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G084",
    type: "Government",
    page: "Finance",
    title: "Department of Finance (Provincial Level)",
    report:
      "Report on the implementation of state budget revenue and borrowing",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZWIzMTIzYzYtY2ZlMi00MTRmLTk5MjktYTA5MmZiM2I1ZTYwIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZWIzMTIzYzYtY2ZlMi00MTRmLTk5MjktYTA5MmZiM2I1ZTYwIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G085",
    type: "Government",
    page: "Finance",
    title: "Department of Finance (Provincial Level)",
    report: "Consolidated report on revenue agencies in the province",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNWY1NjJhNWYtMGM2NC00YjQyLWFjMjMtOThkZDA1YTNhYzM5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNWY1NjJhNWYtMGM2NC00YjQyLWFjMjMtOThkZDA1YTNhYzM5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G086",
    type: "Government",
    page: "Finance",
    title: "Department of Finance (Provincial Level)",
    report: " Report on the implementation of local budget expenditure",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMTllOTg4YjQtNjAyOS00ZmMzLWIzMDItMzNmMTkzMWUxOTBiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMTllOTg4YjQtNjAyOS00ZmMzLWIzMDItMzNmMTkzMWUxOTBiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G087",
    type: "Government",
    page: "Finance",
    title: "Department of Finance (Provincial Level)",
    report:
      "Report on the implementation of the budget for regular expenditure",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYmRjOTVjZDktNTM5Yy00Njk5LTkwY2UtNDE3N2JhM2Q4NjQ2IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYmRjOTVjZDktNTM5Yy00Njk5LTkwY2UtNDE3N2JhM2Q4NjQ2IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G088",
    type: "Government",
    page: "Finance",
    title: "Department of Finance (Provincial Level)",
    report:
      "Report on the implementation of the budget for regular expenditure at the district level",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYmIyMDg0YWQtNmU0NC00ZjA1LWE2YWQtNDMzODFkNDMyYTE0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYmIyMDg0YWQtNmU0NC00ZjA1LWE2YWQtNDMzODFkNDMyYTE0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G089",
    type: "Government",
    page: "Construction",
    title: "Ministry of Construction (Central Level)",
    report: "Consolidated report on domestic construction indicators",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMjI4OTE5MDItMGVlMC00MmE5LWJkMmMtMWI0NTQ2ZTY4NTYyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMjI4OTE5MDItMGVlMC00MmE5LWJkMmMtMWI0NTQ2ZTY4NTYyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
    Content:
      'The "Summary Dashboard of Construction Industry in Vietnam" offers a comprehensive overview of key KPIs within the construction sector, encompassing various areas:\n\n1. How has the number of official residences, social houses, and administrative headquarters changed over the years?\n2. What is the safety situation within the construction industry, including statistics on construction accidents and the associated death toll?\n3. How many newly commenced construction projects have there been, and how many permits have been granted?\n\nThis dashboard not only presents a snapshot of the construction industry\'s performance but also offers detailed information within each KPI, allowing users to delve deeper into specific aspects of the construction sector. Additionally, it provides links to other reports for further analysis and reference.',
  },
  {
    ID: "G090",
    type: "Government",
    page: "Construction",
    title: "Ministry of Construction (Central Level)",
    report: " Summary report by unit to the Ministry of Construction",
    link: "https://app.powerbi.com/view?r=eyJrIjoiY2ZmMTFhMzEtY2ViNC00ZTgzLWI2NDYtNzc2YWQ0YmU4N2Y1IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiY2ZmMTFhMzEtY2ViNC00ZTgzLWI2NDYtNzc2YWQ0YmU4N2Y1IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G092",
    type: "Government",
    page: "Construction",
    title: "Ministry of Construction (Central Level)",
    report: "Area of urban land",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZThiNDU0ZTUtOGNkZC00MTdlLWEzMTAtMDg2YzdlMjZjMjg2IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZThiNDU0ZTUtOGNkZC00MTdlLWEzMTAtMDg2YzdlMjZjMjg2IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G093",
    type: "Government",
    page: "Construction",
    title: "Ministry of Construction (Central Level)",
    report: "Inspection and inspection report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNWE2NjEyZjAtMjEyMC00MThmLTk0NmYtMjg1NDc4MWM2Yzg4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNWE2NjEyZjAtMjEyMC00MThmLTk0NmYtMjg1NDc4MWM2Yzg4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G094",
    type: "Government",
    page: "Construction",
    title: "Ministry of Construction (Central Level)",
    report: "Report on the handling of complaints and denunciations",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMmU1NzI1MjUtY2Q4NS00ZDk5LWEwMjctOGI1NGViZTlhNmY2IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMmU1NzI1MjUtY2Q4NS00ZDk5LWEwMjctOGI1NGViZTlhNmY2IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G095",
    type: "Government",
    page: "Construction",
    title: "Ministry of Construction (Central Level)",
    report: "Real estate situation report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMTVmMjM1MWMtY2FkMy00ZGE0LTliOTItNjZlMWIwMGQzMDE1IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection71e9797164df556bc25e",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMTVmMjM1MWMtY2FkMy00ZGE0LTliOTItNjZlMWIwMGQzMDE1IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection71e9797164df556bc25e",
    typeLink: "VN",
  },
  {
    ID: "G096",
    type: "Government",
    page: "Construction",
    title: "Ministry of Construction (Central Level)",
    report: "Report on the handling of solid waste in urban households",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNDllYmE0MTAtYmMyOC00NjliLTlmZjAtNDcyYWRhZGFjYmMwIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection6ac899d04ac13a554a55",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNDllYmE0MTAtYmMyOC00NjliLTlmZjAtNDcyYWRhZGFjYmMwIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection6ac899d04ac13a554a55",
    typeLink: "VN",
  },
  {
    ID: "G097",
    type: "Government",
    page: "Construction",
    title: "Ministry of Construction (Central Level)",
    report: "Construction project report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiOTc5ODMxOTYtNDViMC00YWQyLWIwMWMtYjA1NzViMmZkMmNlIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiOTc5ODMxOTYtNDViMC00YWQyLWIwMWMtYjA1NzViMmZkMmNlIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G098",
    type: "Government",
    page: "Construction",
    title: "Ministry of Construction (Central Level)",
    report: "Public housing report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYjM5YTc3NjAtNzgwYS00ZTFhLWJkMWUtNWViOWM5MDEwMDU3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection024732c303cd8e68e8d6",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYjM5YTc3NjAtNzgwYS00ZTFhLWJkMWUtNWViOWM5MDEwMDU3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection024732c303cd8e68e8d6",
    typeLink: "VN",
  },
  {
    ID: "G099",
    type: "Government",
    page: "Agriculture and Rural Development",
    title: "Ministry of Agriculture and Rural Development (Central Level)",
    report: "Natural disaster events",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZjZmNGNkYTUtYmQ5YS00MzYyLWEzZjUtZTZhMzJkZDEyYzNkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZjZmNGNkYTUtYmQ5YS00MzYyLWEzZjUtZTZhMzJkZDEyYzNkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
    Content:
      'The "Natural Disaster Events" report provides a comprehensive overview of the damages caused by various types of natural events in the country. By filtering data based on different event types, administrators can gain detailed insights into the extent of damage by year. This report addresses key questions:\n\n1. What is the extent of human loss resulting from these natural disasters?\n2. How much damage has been inflicted on housing, schools, and medical facilities?\n3. What is the extent of damage to agriculture, and how much forested area has been affected?\n\nThis report empowers administrators to assess the impact of natural disasters, allocate resources efficiently, and implement strategies for disaster mitigation and recovery.',
  },
  {
    ID: "G100",
    type: "Government",
    page: "Agriculture and Rural Development",
    title: "Ministry of Agriculture and Rural Development (Central Level)",
    report: "Number of natural disasters by locality",
    link: "https://app.powerbi.com/view?r=eyJrIjoiODgwNTg4ZjUtZGI4OS00MjlmLWFiMDUtMjhjMjFlNTQyNWJkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiODgwNTg4ZjUtZGI4OS00MjlmLWFiMDUtMjhjMjFlNTQyNWJkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G101",
    type: "Government",
    page: "Agriculture and Rural Development",
    title: "Department of Agriculture and Rural Development (Provincial Level)",
    report: "Report on damage caused by natural disasters",
    link: "https://app.powerbi.com/view?r=eyJrIjoiOGJiNjk4ODItYmYwNy00OGM4LTg5MGYtNjY4ZTE1ZjJiYzUwIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiOGJiNjk4ODItYmYwNy00OGM4LTg5MGYtNjY4ZTE1ZjJiYzUwIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G102",
    type: "Government",
    page: "Science and Technology",
    title: "Department of Science and Technology (Provincial Level)",
    report: "Scientific and technological institutions",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYWY4ZGIyNzEtNmJiZC00NzRkLWJiZTYtMTQxODNkZjcyZDdhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYWY4ZGIyNzEtNmJiZC00NzRkLWJiZTYtMTQxODNkZjcyZDdhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
    Content:
      'The "Scientific and Technological Institution" report offers insights into the progress of scientific and research development in the country, addressing crucial questions:\n\n1. What is the proportion of ownership types, including state-owned, foreign, or non-foreign compositions, over time and across different types of organizations?\n2. What are the proportions based on state management types, differentiating between local and central authorities?\n3. How has the number of institutions evolved over the years?\n\nThis report is a valuable resource for understanding the landscape of scientific and technological institutions, their ownership structures, and the impact of state management, providing essential data for informed decision-making and policy planning.',
  },
  {
    ID: "G103",
    type: "Government",
    page: "Science and Technology",
    title: "Department of Science and Technology (Provincial Level)",
    report: "Number of people in scientific and technological organizations",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZTYyODU2OWUtNjAxYi00MTllLTkwZmItZTViMDA0Mzc3NGEwIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZTYyODU2OWUtNjAxYi00MTllLTkwZmItZTViMDA0Mzc3NGEwIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G104",
    type: "Government",
    page: "Science and Technology",
    title: "Department of Science and Technology (Provincial Level)",
    report: "Expenditure for science and technology",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNTAxNDBlYWUtYTIwOS00ZGUwLTgzMWUtZTllY2U4Mjg0ZTYzIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNTAxNDBlYWUtYTIwOS00ZGUwLTgzMWUtZTllY2U4Mjg0ZTYzIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G105",
    type: "Government",
    page: "Science and Technology",
    title: "Department of Science and Technology (Provincial Level)",
    report: "Scientific and technological tasks",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMTFjN2I2YWYtYTIwOS00ZGFjLWI2ZjktZjYxZjNlNmE5NGJiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMTFjN2I2YWYtYTIwOS00ZGFjLWI2ZjktZjYxZjNlNmE5NGJiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G106",
    type: "Government",
    page: "Science and Technology",
    title: "Department of Science and Technology (Provincial Level)",
    report:
      "International cooperation in scientific and technological activities",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMGFiMWIxZDMtMDAzOC00MmMzLWJhMWUtZDBjNzM0ODRmYzg1IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMGFiMWIxZDMtMDAzOC00MmMzLWJhMWUtZDBjNzM0ODRmYzg1IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G107",
    type: "Government",
    page: "Science and Technology",
    title: "Department of Science and Technology (Provincial Level)",
    report: "Technology transfer",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYzkyZDA1MDUtMDY2OS00MDM1LWFkNGMtZmI4YTIwNWU2OTc3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&fbclid=IwAR2CjuAGsIdqXrwaDEJSpS-PWw3_7Bpcw4zhHEjyTBOg7oGaoq5wa7221dw",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYzkyZDA1MDUtMDY2OS00MDM1LWFkNGMtZmI4YTIwNWU2OTc3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&fbclid=IwAR2CjuAGsIdqXrwaDEJSpS-PWw3_7Bpcw4zhHEjyTBOg7oGaoq5wa7221dw",
    typeLink: "VN",
  },
  {
    ID: "G108",
    type: "Government",
    page: "Science and Technology",
    title: "Department of Science and Technology (Provincial Level)",
    report: "Enterprises and the science and technology market",
    link: "https://app.powerbi.com/view?r=eyJrIjoiN2MwYTM2NzgtMGYxYy00YWEwLThlNmEtZmEzZDg4ODZhYTQyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiN2MwYTM2NzgtMGYxYy00YWEwLThlNmEtZmEzZDg4ODZhYTQyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G109",
    type: "Government",
    page: "Science and Technology",
    title: "Department of Science and Technology (Provincial Level)",
    report: "Intellectual property",
    link: "https://app.powerbi.com/view?r=eyJrIjoiOTdmZTk1MmMtOThjMi00ZWExLWJhZGQtYzY2MDhhNmY4ZWIwIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiOTdmZTk1MmMtOThjMi00ZWExLWJhZGQtYzY2MDhhNmY4ZWIwIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G110",
    type: "Government",
    page: "Science and Technology",
    title: "Department of Science and Technology (Provincial Level)",
    report: "Standards for measurement and quality",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMWI2MWQ4MmQtMjA2My00YTgyLWI3YmYtODZiZDFmMTMxY2FhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMWI2MWQ4MmQtMjA2My00YTgyLWI3YmYtODZiZDFmMTMxY2FhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G111",
    type: "Government",
    page: "Science and Technology",
    title: "Department of Science and Technology (Provincial Level)",
    report: "Nuclear energy, radiation safety, and nuclear safety",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMGNjMDczZjQtZTI5MS00YWI3LWI5MDYtNmM0MjgzODNiMzI1IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMGNjMDczZjQtZTI5MS00YWI3LWI5MDYtNmM0MjgzODNiMzI1IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G112",
    type: "Government",
    page: "Industry and Trade",
    title: "Ministry of Industry and Trade (Central Level)",
    report: "Import - Export turnover",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMTQyNThkYmUtNzM0NS00ODRkLWI5ZTUtNjYzZDIyZWE0YmY5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMTQyNThkYmUtNzM0NS00ODRkLWI5ZTUtNjYzZDIyZWE0YmY5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
    Content:
      "The report helps the Ministry of Industry and Trade monitor the performance of import and export turnover of goods, two important metrics. It supports in analyzing the trade balance of import and export of goods over time, by country. The report compares the current period of import and export turnover with last period according to product types. It could help users evaluate turnover value and plan better strategies for the future. \nIt answers the following questions:\n\n1. Does the goods' value of import and export grow and keep balance over time?\n2. Which country have the best import and export value?\n3. Which types of products are import and export most?",
    Column11: "hangntm",
  },
  {
    ID: "G113",
    type: "Government",
    page: "Industry and Trade",
    title: "Ministry of Industry and Trade (Central Level)",
    report: "Summary of the situation of human resource training",
    link: "https://app.powerbi.com/view?r=eyJrIjoiODNjNDRjYTUtOTIyMi00ZTU2LTgzMjgtYTUxMjg5MzM4MjAyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionab8c890c78a38a232f33",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiODNjNDRjYTUtOTIyMi00ZTU2LTgzMjgtYTUxMjg5MzM4MjAyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionab8c890c78a38a232f33",
    typeLink: "VN",
  },
  {
    ID: "G114",
    type: "Government",
    page: "Industry and Trade",
    title: "Ministry of Industry and Trade (Central Level)",
    report: "Summary of the situation of implementing the rural new planning",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNDQwMmM1NjYtZWIwMC00Mzg3LWJhNDItYjA4ODU2Y2IyZjA4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNDQwMmM1NjYtZWIwMC00Mzg3LWJhNDItYjA4ODU2Y2IyZjA4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G115",
    type: "Government",
    page: "Industry and Trade",
    title: "Ministry of Industry and Trade (Central Level)",
    report: "Summary of the situation of the operation of industrial clusters",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZWNlNjdmYjctYTYyMS00Yjk2LWFlMjEtZWM1NTk0NmFiYTc4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZWNlNjdmYjctYTYyMS00Yjk2LWFlMjEtZWM1NTk0NmFiYTc4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    typeLink: "VN",
  },
  {
    ID: "G116",
    type: "Government",
    page: "Industry and Trade",
    title: "Ministry of Industry and Trade (Central Level)",
    report: "Summary of the current situation of market management",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYTQ3ODhmNmUtZWM0OC00MWZmLWE2YjUtOGM5MDQ1MzlhOWNjIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection019a1ba5e546d4dbc0e2",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYTQ3ODhmNmUtZWM0OC00MWZmLWE2YjUtOGM5MDQ1MzlhOWNjIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection019a1ba5e546d4dbc0e2",
    typeLink: "VN",
  },
  {
    ID: "G117",
    type: "Government",
    page: "Industry and Trade",
    title: "Ministry of Industry and Trade (Central Level)",
    report: "Total retail sales of goods and service revenue",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMWVkMDA0YmMtZDFhOC00OTAwLTgwMTQtMGRlY2ZiNTVkYTBkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionfb862d6114286040b2a1",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMWVkMDA0YmMtZDFhOC00OTAwLTgwMTQtMGRlY2ZiNTVkYTBkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionfb862d6114286040b2a1",
    typeLink: "VN",
  },
  {
    ID: "G118",
    type: "Government",
    page: "Industry and Trade",
    title: "Ministry of Industry and Trade (Central Level)",
    report: " Investment report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiODZhYWExZDYtNDE2MC00M2ZiLTgxM2MtYjliYjNjOGMwMDBiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiODZhYWExZDYtNDE2MC00M2ZiLTgxM2MtYjliYjNjOGMwMDBiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    typeLink: "VN",
  },
  {
    ID: "G119",
    type: "Government",
    page: "Industry and Trade",
    title: "Ministry of Industry and Trade (Central Level)",
    report: " Report on labor and income",
    link: "https://app.powerbi.com/view?r=eyJrIjoiM2MyYzQ1NTQtNTAyYi00ZWMxLWI0YzUtMDhjY2Y3NWJmZGQ5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiM2MyYzQ1NTQtNTAyYi00ZWMxLWI0YzUtMDhjY2Y3NWJmZGQ5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G120",
    type: "Government",
    page: "Industry and Trade",
    title: "Ministry of Industry and Trade (Central Level)",
    report:
      "Summary report on the production, consumption, and inventory of industrial products by year",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNzBhNTEzNTktMDFmMy00YmRmLTlmOWEtY2NjODM0MTdjZDBlIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection55cd151e839a507d3b15",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNzBhNTEzNTktMDFmMy00YmRmLTlmOWEtY2NjODM0MTdjZDBlIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection55cd151e839a507d3b15",
    typeLink: "VN",
  },
  {
    ID: "G121",
    type: "Government",
    page: "Industry and Trade",
    title: "Ministry of Industry and Trade (Central Level)",
    report: " Industrial promotion report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNzg1ZWNjYmUtY2RiYS00MThjLWIyZjctYjBjYTUyOGQ3MjA4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D\n",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNzg1ZWNjYmUtY2RiYS00MThjLWIyZjctYjBjYTUyOGQ3MjA4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G122",
    type: "Government",
    page: "Industry and Trade",
    title: "Ministry of Industry and Trade (Central Level)",
    report: " Market, supermarket, and shopping center development work",
    link: "https://app.powerbi.com/view?r=eyJrIjoiOGE4YmE5YTgtMzQ5Mi00MGZmLWE1ZGYtNWU5YzRjMzQ4NzlmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiOGE4YmE5YTgtMzQ5Mi00MGZmLWE1ZGYtNWU5YzRjMzQ4NzlmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G123",
    type: "Government",
    page: "Industry and Trade",
    title: "Ministry of Industry and Trade (Central Level)",
    report: "Number of units with e-commerce transactions",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMTc3N2I0ZjgtY2FjNS00NDFjLTk1NjgtZjE1YTMxNjUyODBiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMTc3N2I0ZjgtY2FjNS00NDFjLTk1NjgtZjE1YTMxNjUyODBiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G124",
    type: "Government",
    page: "Industry and Trade",
    title: "Department of Industry and Trade (Provincial Level)",
    report: "Import and export",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMjg1NzdkNTAtNDhlOS00OWYxLTkzZjQtZjRlYmM4YTM0OGYyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMjg1NzdkNTAtNDhlOS00OWYxLTkzZjQtZjRlYmM4YTM0OGYyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G125",
    type: "Government",
    page: "Industry and Trade",
    title: "Department of Industry and Trade (Provincial Level)",
    report: "Service",
    link: "https://app.powerbi.com/view?r=eyJrIjoiOWNmNzA5OGUtY2M4Yi00MzA2LTgxNTEtZWJjYmExYTYxOGRhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiOWNmNzA5OGUtY2M4Yi00MzA2LTgxNTEtZWJjYmExYTYxOGRhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G126",
    type: "Government",
    page: "Industry and Trade",
    title: "Department of Industry and Trade (Provincial Level)",
    report: " Industry",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMzlkMjYzZGUtNWY0My00Nzg1LThlN2EtNWJjOWNmNTNhNzBkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMzlkMjYzZGUtNWY0My00Nzg1LThlN2EtNWJjOWNmNTNhNzBkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G127",
    type: "Government",
    page: "Internal Affairs",
    title: "Ministry of Home Affairs (Central Level)",
    report: "Report on the Field of Religion and Belief",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMWY4ZWM2Y2QtZDc1MC00NzUzLTkxOTMtNGNhODZkYzEyMmI0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMWY4ZWM2Y2QtZDc1MC00NzUzLTkxOTMtNGNhODZkYzEyMmI0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
    Content:
      "\nReligion is a system of beliefs and practices that relate humanity to spirituality and, sometimes, to moral values. It provides a way for people to connect with something larger than themselves and to find meaning and purpose in life.\n\nThis dashboard helps users to answers these questions:\n1. Over the years, how has the religiousity of this country progressed?\n2. How many dignitaries, clergy, places or worship have been built for each religion?\n3. What do the proportions of the religions look like?",
  },
  {
    ID: "G128",
    type: "Government",
    page: "Internal Affairs",
    title: "Ministry of Home Affairs (Central Level)",
    report: "Organizational and Personnel Report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNDExZjkwMmQtOGFjZS00OTA0LWFmOWYtYWZhOGFiM2ZhMzExIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNDExZjkwMmQtOGFjZS00OTA0LWFmOWYtYWZhOGFiM2ZhMzExIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G129",
    type: "Government",
    page: "Internal Affairs",
    title: "Ministry of Home Affairs (Central Level)",
    report:
      "Report on Civil Servants, Public Servants and Civil Servants-Training",
    link: "https://app.powerbi.com/view?r=eyJrIjoiOWZjMGYwNjctMjNjMy00NTIwLThkNTctYjY0NTkwMGRiM2E0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection9590711bed9ce0baa92b",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiOWZjMGYwNjctMjNjMy00NTIwLThkNTctYjY0NTkwMGRiM2E0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection9590711bed9ce0baa92b",
    typeLink: "VN",
  },
  {
    ID: "G130",
    type: "Government",
    page: "Internal Affairs",
    title: "Ministry of Home Affairs (Central Level)",
    report: "Report on Organizational Management, Streamlining of Staffing",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZjAyODUyODctMWIzYy00OWZlLWI3M2MtMzY1ZGI0OWU3MmZiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection0c770eabcc5d00d0302c",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZjAyODUyODctMWIzYy00OWZlLWI3M2MtMzY1ZGI0OWU3MmZiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection0c770eabcc5d00d0302c",
    typeLink: "VN",
  },
  {
    ID: "G131",
    type: "Government",
    page: "Internal Affairs",
    title: "Ministry of Home Affairs (Central Level)",
    report: "Report on Associations, Funds, Non-Governmental Organizations",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMTg5ZjRiYWQtY2VhNC00ZGZmLTlmNWEtZTcwYWIyNDVmZGEyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMTg5ZjRiYWQtY2VhNC00ZGZmLTlmNWEtZTcwYWIyNDVmZGEyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G132",
    type: "Government",
    page: "Internal Affairs",
    title: "Ministry of Home Affairs (Central Level)",
    report: "Report on the Number of People's Council Deputies",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMjRiNWM5YzYtZTAyMS00OTE5LTlkYWMtMTJmZTQyOWI3NjhiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMjRiNWM5YzYtZTAyMS00OTE5LTlkYWMtMTJmZTQyOWI3NjhiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G133",
    type: "Government",
    page: "Internal Affairs",
    title: "Ministry of Home Affairs (Central Level)",
    report: "Report on the Number of Civil Servants",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMzFhNDUyNTctM2Q3MC00M2Y4LTgwNTItNDRlYzE4NjNkNDVkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMzFhNDUyNTctM2Q3MC00M2Y4LTgwNTItNDRlYzE4NjNkNDVkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G134",
    type: "Government",
    page: "Internal Affairs",
    title: "Ministry of Home Affairs (Central Level)",
    report: "Report on the Number of State Awards",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMzBiZDQwY2QtZThmNC00YTYwLTg2ZGItNmJiNGQ4ZWJhOTU5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMzBiZDQwY2QtZThmNC00YTYwLTg2ZGItNmJiNGQ4ZWJhOTU5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G135",
    type: "Government",
    page: "Internal Affairs",
    title: "Ministry of Home Affairs (Central Level)",
    report: "Report on the Allocation of Public School Teacher Positions",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNDA4ODY5MGItY2NiZC00MGE5LTgyYjItOTExOWZjZjY5Y2QxIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNDA4ODY5MGItY2NiZC00MGE5LTgyYjItOTExOWZjZjY5Y2QxIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G136",
    type: "Government",
    page: "Labor",
    title: "Department of Labor (Provincial Level)",
    report: "Provincial Labor and Employment Report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiOWUzNmMwYzAtNWY5NS00ODgxLTgzOGEtZmZiMzM1ZmIxZTMwIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiOWUzNmMwYzAtNWY5NS00ODgxLTgzOGEtZmZiMzM1ZmIxZTMwIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
    Content:
      "Provincial labor and employment report has become one of the most powerful mediums for local government connect with the citizens. It contains information about number of labors, growth rate, unemployment rate, underemployment rate. These data shed light on employment trend over time and by classification (by economic sector, by gender and by region).\nIt generally sets the tone for the other economic indicators that are released throughout the month. Government uses data from this report to determine whether it should raise, lower, or maintain interest rates in order to keep the economy sound.\n\nThe report provides answers to the below question:\n1. What is the unemployment rate and underemployment rate over the years?\n2. How is the labor force structure distributed by gender, economic sector, region?\n3. How has the labor growth rate changed over the years?",
    Column11: "hangntm",
  },
  {
    ID: "G137",
    type: "Government",
    page: "Labor",
    title:
      "Ministry of Labour, War Invalids and Social Affairs (Central Level)",
    report: "National Labor Force Report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZWYyZDIxZWQtMDhjZC00NGM0LTkzZGEtZWRhZjJjNWRmMDM3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZWYyZDIxZWQtMDhjZC00NGM0LTkzZGEtZWRhZjJjNWRmMDM3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G138",
    type: "Government",
    page: "Health",
    title: "Provincial Department of Health",
    report: "The situation of infection and death from infectious diseases",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMTU2ZGRiYTMtMmE4ZC00NDk3LTg4OGEtYjA5MDg3YWE2NjM4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMTU2ZGRiYTMtMmE4ZC00NDk3LTg4OGEtYjA5MDg3YWE2NjM4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
    Content:
      "Infectious diseases are caused by pathogens and  are a major public health concern, causing millions of deaths each year.\nThe dashboard provides answers to the below questions:\n1. What's the epidemiological situation over the years?\n2. By locality, what is the number of cases and death toll overall?\n3. What are the diseases with the most infected cases and death toll?",
  },
  {
    ID: "G139",
    type: "Government",
    page: "Health",
    title: "Provincial Department of Health",
    report: "Basic health information",
    link: "https://app.powerbi.com/view?r=eyJrIjoiODZjM2M3YjgtYjI4ZS00NTFiLWExY2YtYWI3MTNlMDIyOGQ5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiODZjM2M3YjgtYjI4ZS00NTFiLWExY2YtYWI3MTNlMDIyOGQ5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G140",
    type: "Government",
    page: "Health",
    title: "Provincial Department of Health",
    report: "Health insurance",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNmZiOTcxYTEtYzc2My00NmU0LWIxOGYtZmJmMDdmZmU4MzZmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNmZiOTcxYTEtYzc2My00NmU0LWIxOGYtZmJmMDdmZmU4MzZmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G141",
    type: "Government",
    page: "Health",
    title: "Provincial Department of Health",
    report: "Report on medical examination and treatment activities",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMjllYjA1YTQtOGI4MC00MTM4LTk2ZDUtNzRiZDYxYzNkODgyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMjllYjA1YTQtOGI4MC00MTM4LTk2ZDUtNzRiZDYxYzNkODgyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G142",
    type: "Government",
    page: "Health",
    title: "Provincial Department of Health",
    report: "Management of provincial health personnel",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYzE1NGRlMDItMmQ3YS00OTFlLTgzOWYtYzZmZTI2MmU2MjM0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYzE1NGRlMDItMmQ3YS00OTFlLTgzOWYtYzZmZTI2MmU2MjM0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G143",
    type: "Government",
    page: "Health",
    title: "Provincial Department of Health",
    report: "Preventive medicine",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNTkxMTcyZWEtNDZkNC00YmQwLTkwY2QtYzU2Y2RkMDVjYjBjIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNTkxMTcyZWEtNDZkNC00YmQwLTkwY2QtYzU2Y2RkMDVjYjBjIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G144",
    type: "Government",
    page: "Health",
    title: "Provincial Department of Health",
    report: "The situation of Covid-19 in the province",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMzYwY2M5NzEtMjUwYi00ZWU5LWJiYjUtZmQzNjNjMGQzNjE4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionbc9409d80a3213405928",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMzYwY2M5NzEtMjUwYi00ZWU5LWJiYjUtZmQzNjNjMGQzNjE4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionbc9409d80a3213405928",
    typeLink: "VN",
  },
  {
    ID: "G145",
    type: "Government",
    page: "Local Government",
    title: "Sectorial Summary Report",
    report: "Department of Finance",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMzAxYWNjNjItYmIwMy00NDMyLTg2NzEtZmUwNmRiOGY2MTdhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMzAxYWNjNjItYmIwMy00NDMyLTg2NzEtZmUwNmRiOGY2MTdhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
    Content:
      "The Department of Finance is responsible for managing the government's financial resources and ensuring that they are used effectively and efficiently. This annual report provides a comprehensive overview of the department's financial performance and its key achievements and challenges over the past year. \nThis report delivers information on:\n\n1. How do key financial indicators, such as budget revenue, budget expenditure, return on equity, number of state-owned enterprises perform over years?\n2. Which specific industry contributes the most to the total budget revenue in selected year?\n3. How has the profit-to-budget ratio of state-owned enterprises changed over the years?\n\nThis annual report is an important tool for accountability and transparency. It provides the public and government officials with information about the department's financial performance, its strategic goals, and its key achievements and challenges. The report can also be used to identify areas where the department can improve its performance.",
    Column11: "hangntm",
  },
  {
    ID: "G146",
    type: "Government",
    page: "Local Government",
    title: "e-government",
    report: "Document management",
    link: "https://app.powerbi.com/view?r=eyJrIjoiN2MzMjdlNDktMmM1Ny00NTcwLWFkNDYtMTAwYjY2OTk0ODZmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiN2MzMjdlNDktMmM1Ny00NTcwLWFkNDYtMTAwYjY2OTk0ODZmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G147",
    type: "Government",
    page: "Local Government",
    title: "e-government",
    report: "Tasks accomplishment",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMTU2MTU2MTgtZWNhMy00YjZlLTk0ZGQtOTkwNGJjYjhlNDk4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMTU2MTU2MTgtZWNhMy00YjZlLTk0ZGQtOTkwNGJjYjhlNDk4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G148",
    type: "Government",
    page: "Local Government",
    title: "e-government",
    report: "Administrative Procedure Resolution",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYzQyYzY1Y2ItMTIwOC00YTJhLWFiYWItZjA2OTdiZDkzYTA3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYzQyYzY1Y2ItMTIwOC00YTJhLWFiYWItZjA2OTdiZDkzYTA3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G149",
    type: "Government",
    page: "Local Government",
    title: "e-government",
    report: "Public Opinion Processing",
    link: "https://app.powerbi.com/view?r=eyJrIjoiOTkxZjBmYWMtNzUzOS00NjJlLWFmNzctN2FhOGVkM2NmN2EzIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiOTkxZjBmYWMtNzUzOS00NjJlLWFmNzctN2FhOGVkM2NmN2EzIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G150",
    type: "Government",
    page: "Local Government",
    title: "Sectorial Summary Report",
    report: "Department of Education and Training",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNGMwNzAwNGYtNzM0NC00NzQzLTkzMTQtN2ZhYTE0NDk2YWExIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNGMwNzAwNGYtNzM0NC00NzQzLTkzMTQtN2ZhYTE0NDk2YWExIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G151",
    type: "Government",
    page: "Local Government",
    title: "Sectorial Summary Report",
    report: "Department of Transport",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMWQ2ZjQ2YTktOGM4ZC00YzgyLTgxMTctNjdjZmVlYmU1ZmM3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMWQ2ZjQ2YTktOGM4ZC00YzgyLTgxMTctNjdjZmVlYmU1ZmM3IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G152",
    type: "Government",
    page: "Local Government",
    title: "Sectorial Summary Report",
    report: "Department of Planning and Investment",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMjJkYmQ1ZjMtZGJmZS00MGNmLTkzMDQtZjU2NGM5YmUyZGI4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMjJkYmQ1ZjMtZGJmZS00MGNmLTkzMDQtZjU2NGM5YmUyZGI4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G153",
    type: "Government",
    page: "Local Government",
    title: "Sectorial Summary Report",
    report: "Department of Agriculture and Rural Development",
    link: "https://app.powerbi.com/view?r=eyJrIjoiY2U2MTA2MWEtNzdlYi00ODJlLThjMjEtOTAxYzM0NjZkNDM5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiY2U2MTA2MWEtNzdlYi00ODJlLThjMjEtOTAxYzM0NjZkNDM5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G154",
    type: "Government",
    page: "Local Government",
    title: "Sectorial Summary Report",
    report: "Department of Natural Resources and Environment",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYjYyNGQ4NDYtZTkzNC00MTc0LTgyOWEtYzU2OTMxMGJhNzNhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYjYyNGQ4NDYtZTkzNC00MTc0LTgyOWEtYzU2OTMxMGJhNzNhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G155",
    type: "Government",
    page: "Local Government",
    title: "Sectorial Summary Report",
    report: "Department of Culture, Sports and Tourism",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNWY4MThmZjUtN2VlMy00YjhkLWI1MGEtMjI0NDUxYzU0M2JiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNWY4MThmZjUtN2VlMy00YjhkLWI1MGEtMjI0NDUxYzU0M2JiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G156",
    type: "Government",
    page: "Local Government",
    title: "Sectorial Summary Report",
    report: "Department of Health",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYmEwMDFkNzEtMTJiNC00MDA5LWI0N2QtMzczNDljMjJiNTMyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYmEwMDFkNzEtMTJiNC00MDA5LWI0N2QtMzczNDljMjJiNTMyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G157",
    type: "Government",
    page: "Local Government",
    title: "Sectorial Summary Report",
    report: "Department of Industry and Trade",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMTY2NmZjZDYtZGJkYi00ZjliLWE4NDMtYTU2NWI1YmQ3MGExIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMTY2NmZjZDYtZGJkYi00ZjliLWE4NDMtYTU2NWI1YmQ3MGExIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G158",
    type: "Government",
    page: "Local Government",
    title: "Sectorial Summary Report",
    report: "Department of Labor, War Invalids and Social Affairs",
    link: "https://app.powerbi.com/view?r=eyJrIjoiODFiN2ZjMzctYWU0Yi00OWE2LWI0NTgtNzE2NmQxNzU3N2UyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiODFiN2ZjMzctYWU0Yi00OWE2LWI0NTgtNzE2NmQxNzU3N2UyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G159",
    type: "Government",
    page: "Local Government",
    title: "Sectorial Summary Report",
    report: "Department of Construction",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNGQxNjA2ZmQtMWQ4ZC00N2VkLWFjMDUtYWQyZWQ3N2EwZDQ0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNGQxNjA2ZmQtMWQ4ZC00N2VkLWFjMDUtYWQyZWQ3N2EwZDQ0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G160",
    type: "Government",
    page: "Local Government",
    title: "Sectorial Summary Report",
    report: "Department of Home Affairs",
    link: "https://app.powerbi.com/view?r=eyJrIjoiODEwYTZiNzUtNDZjYi00MGJlLTgyY2EtOGMzMTRmOWY3OGU2IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiODEwYTZiNzUtNDZjYi00MGJlLTgyY2EtOGMzMTRmOWY3OGU2IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G161",
    type: "Government",
    page: "Local Government",
    title: "Sectorial Summary Report",
    report: "Provincial Police",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNTc2M2FhMmMtMjJlMi00OGE4LTgyMTgtYjI5M2FhMThhY2EwIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNTc2M2FhMmMtMjJlMi00OGE4LTgyMTgtYjI5M2FhMThhY2EwIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G162",
    type: "Government",
    page: "Local Government",
    title: "Sectorial Summary Report",
    report: "Provincial Radio and Television Station",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYzQwZjdkMWUtMzRlMS00ODZlLTkwNWMtYjEzZjUwZmJmMjVhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYzQwZjdkMWUtMzRlMS00ODZlLTkwNWMtYjEzZjUwZmJmMjVhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G163",
    type: "Government",
    page: "Local Government",
    title: "Sectorial Summary Report",
    report: "Department of Information and Communications",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMmQxOWFkNDMtZjBhYS00OTI5LTlkMjEtYjYyNDhlZGFhZWQ5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMmQxOWFkNDMtZjBhYS00OTI5LTlkMjEtYjYyNDhlZGFhZWQ5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G164",
    type: "Government",
    page: "Vietnam Academy of Science and Technology",
    title: "Vietnam Academy of Science and Technology",
    report: "Scientific works",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMDBkNzFlNjUtN2JhZS00YWNkLThkYzktYmU0MWI1YmZlODVjIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMDBkNzFlNjUtN2JhZS00YWNkLThkYzktYmU0MWI1YmZlODVjIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
    Content:
      "The Vietnam Academy of Science and Technology is the largest and most prominent research institute in Vietnam. \nThe dashboard gives answers to the questions:\n1. How many works do the institutes have over years?\n2. What type and category is the most prominent among the works?\n3. What are the most prolific units? ",
  },
  {
    ID: "G165",
    type: "Government",
    page: "Vietnam Academy of Science and Technology",
    title: "Vietnam Academy of Science and Technology",
    report: "Selected basic research topics",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNmNlNWY2ZGYtZGM1NC00ZTQyLWI5YjktNDZhMzlhNzM5MjRkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNmNlNWY2ZGYtZGM1NC00ZTQyLWI5YjktNDZhMzlhNzM5MjRkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G166",
    type: "Government",
    page: "Vietnam Academy of Science and Technology",
    title: "Vietnam Academy of Science and Technology",
    report: "The situation of topics, tasks, and funding",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMmM5OTc3MjEtNzFlMS00MTdiLTk4YzktYjlmNThhMDg4NGU4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMmM5OTc3MjEtNzFlMS00MTdiLTk4YzktYjlmNThhMDg4NGU4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G167",
    type: "Government",
    page: "Vietnam Academy of Science and Technology",
    title: "Vietnam Academy of Science and Technology",
    report: "The situation of scientific and technological contracts",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZTdiN2Y3NjQtZDc5Yy00YmNmLThlNTQtY2NmYjE1ZDg5Mzg4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZTdiN2Y3NjQtZDc5Yy00YmNmLThlNTQtY2NmYjE1ZDg5Mzg4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G168",
    type: "Government",
    page: "Vietnam Academy of Science and Technology",
    title: "Vietnam Academy of Science and Technology",
    report: "The situation of human resources",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNmVkODk3ZTYtYzhlYy00N2Q4LThlYTktNDI4MjM5M2JmM2VlIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNmVkODk3ZTYtYzhlYy00N2Q4LThlYTktNDI4MjM5M2JmM2VlIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G169",
    type: "Government",
    page: "Vietnam Academy of Science and Technology",
    title: "Vietnam Academy of Science and Technology",
    report: "The situation of the state budget appropriation",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNTZlZDUwZGMtODcwYS00NDc0LWE3ZDMtMTRjMDg5YzE2NDJhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNTZlZDUwZGMtODcwYS00NDc0LWE3ZDMtMTRjMDg5YzE2NDJhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G170",
    type: "Government",
    page: "Vietnam Academy of Science and Technology",
    title: "Vietnam Academy of Science and Technology",
    report: "Statistics on the number of intellectual property certificates",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMGVmMzQxOTYtNGQyYS00MDliLWE2YWItM2FhNmNiMzJiMWE5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMGVmMzQxOTYtNGQyYS00MDliLWE2YWItM2FhNmNiMzJiMWE5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    typeLink: "VN",
  },
  {
    ID: "G171",
    type: "Government",
    page: "Vietnam Social Security",
    title: "Vietnam Social Security Central",
    report: "Statistics of the expenditure on health insurance medical care",
    link: "https://app.powerbi.com/view?r=eyJrIjoiY2ZhZjkyNGMtMWY0Ni00NjA3LTkwMjYtZDYyMjdkNWRkMzgwIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiY2ZhZjkyNGMtMWY0Ni00NjA3LTkwMjYtZDYyMjdkNWRkMzgwIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
    Content:
      "The dashboard provides statistics on the expenditure on health insurance medical care for the social security central of government. This dashboard can be used to monitor the utilization and cost of health insurance medical care. It can also be used to identify areas where there is a need to improve access to health insurance medical care or to reduce costs.\nIt answers the below questions:\n\n1. Which area has highest or lowest utilization of health insurance medical care?\n2. How number of medical examinations and expenditures covered by health insurance change over time?\n3. How are insurance expenditures and number of medical examinations allocated by treatment types?",
    Column11: "hangntm",
  },
  {
    ID: "G172",
    type: "Government",
    page: "Vietnam Social Security",
    title: "Vietnam Social Security Central",
    report:
      "Collection and disbursement of social security, unemployment insurance, and health insurance funds",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZDhhMjllY2ItZTU3OC00OWJiLWI4OWMtOTNiYTVmYWY1YWQwIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZDhhMjllY2ItZTU3OC00OWJiLWI4OWMtOTNiYTVmYWY1YWQwIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G173",
    type: "Government",
    page: "Vietnam Social Security",
    title: "Vietnam Social Security Central",
    report:
      "Comprehensive statistics of the collection and disbursement results of social security, unemployment insurance, and health insurance funds",
    link: "https://app.powerbi.com/view?r=eyJrIjoiM2QzZjQ2NGEtNmYwMC00ZGNhLTllMWItOGIwZmQ4ZDAyY2FkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiM2QzZjQ2NGEtNmYwMC00ZGNhLTllMWItOGIwZmQ4ZDAyY2FkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G174",
    type: "Government",
    page: "Vietnam Social Security",
    title: "Vietnam Social Security Central",
    report:
      "Statistics of the collection of social security, unemployment insurance, and health insurance funds by management unit",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNGU1ZmNhYWUtNjJjNC00YWRhLWJlNGUtY2JkMzI4YjJjODUxIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNGU1ZmNhYWUtNjJjNC00YWRhLWJlNGUtY2JkMzI4YjJjODUxIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "G175",
    type: "Government",
    page: "Vietnam Social Security",
    title: "Vietnam Social Security Central",
    report:
      "Statistics of the number of people and the amount of money paid for social security and unemployment insurance benefits",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZTI5NTg1ZTctOWQzZS00ZGI1LWIwMjMtMzFkMDM5NjVmOTc5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZTI5NTg1ZTctOWQzZS00ZGI1LWIwMjMtMzFkMDM5NjVmOTc5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "B001",
    type: "Business",
    page: "University",
    report: " Achievements and other information",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMDNiZWM2NjUtMWEzMC00MTBmLWE3NjYtZDljM2QyYzE3MGZkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMDNiZWM2NjUtMWEzMC00MTBmLWE3NjYtZDljM2QyYzE3MGZkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
    Content:
      "This report informs viewers on the academic performance of students, their achievements and also expenses used on facilities. It helps answer these concerns:\n1. Over the years, how is the academic performance of students? Is it alarming? \n2. How many awards have students achieve on different levels in the last 5 years?\n3. What are the sources of investments used on the facilities?   ",
  },
  {
    ID: "B002",
    type: "Business",
    page: "University",
    report: "Scale of training",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZDliNDJhNjctZWFkZi00ZDc1LWE5OTktYTQ4YmQwM2QxMTc2IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection4845a16ab66cc33c202e",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZDliNDJhNjctZWFkZi00ZDc1LWE5OTktYTQ4YmQwM2QxMTc2IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection4845a16ab66cc33c202e",
    typeLink: "VN",
  },
  {
    ID: "B003",
    type: "Business",
    page: "University",
    report: "Admission",
    link: "https://app.powerbi.com/view?r=eyJrIjoiY2FhMDQ2MzUtZGZhNy00NThmLTkxZWQtYmRjNDU4ODM1NDU0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiY2FhMDQ2MzUtZGZhNy00NThmLTkxZWQtYmRjNDU4ODM1NDU0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "B004",
    type: "Business",
    page: "University",
    report: "Financial and infrastructure management",
    link: "LINK",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNWY1ODZjNmYtYmFjNi00YThmLWI0YzUtYTk1NDQyM2Q3YTQzIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "B005",
    type: "Business",
    page: "University",
    report: "Employment status of graduates",
    link: "LINK",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiOTgxZThjMjYtYmY1ZC00NTk5LWI4MmQtMDRiNDMzMzc2MjIzIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "B006",
    type: "Business",
    page: "University",
    report: "Tracking of students who have dropped out",
    link: "LINK",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMDk4MTUwOTAtMmIzMS00YTNlLWE2ZjMtNzBkZTI1ZDBjMDNhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "B007",
    type: "Business",
    page: "University",
    report: "Training program management",
    link: "LINK",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNGRjN2IxZmMtZWY0My00NTQyLTgyYWYtMmM1NDQ1MzI5ZTNkIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "B008",
    type: "Business",
    page: "University",
    report: " Lecturer management",
    link: "LINK",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZDY2Y2JlNTAtZWNjZi00N2Y3LTliZDMtMGY4ZDdlN2Y3MzYwIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "B009",
    type: "Business",
    page: "E-commerce",
    report: "Sales",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZWZiOTY5ZjAtNDg1NC00ZjA1LTkyYTEtMDVjOTE3YTkyMTA1IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection5c103b16db281e059040",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZWZiOTY5ZjAtNDg1NC00ZjA1LTkyYTEtMDVjOTE3YTkyMTA1IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection5c103b16db281e059040",
    typeLink: "EN",
    Content:
      "Sales cannibalization report intends to analyze the “cannibalization” in business which is defined as a loss in sales caused by a company’s introduction of a new product that displaces one of its own older products. This interactive dashboard allows users to choose two products, track their net sales in units and values and put them into comparison.\nThe observation of the visualization indicates whether a previously released product is being cannibalized by a newer one. It is crucial to take into account how the two items are marketed in order to prevent new products from eroding the sales of existing ones. High market cannibalization risk exists for products with comparable placement and pricing, and new offerings can be carefully scheduled to prevent competing with existing ones. \nIt answers the below questions:\n\n1. Were the sales of one product affected by the introduction of another product?\n2. How are one product's net sales compared to another's?",
    Column11: "hangntm",
  },
  {
    ID: "B010",
    type: "Business",
    page: "E-commerce",
    report: "Inventory",
    link: "LINK",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZmU2NTQ0ZDItNjIyMS00MjE2LTk3NzAtYzkxMGFmZjBiNjVmIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection72b45e9b940049315205",
    typeLink: "EN",
  },
  {
    ID: "B011",
    type: "Business",
    page: "E-commerce",
    report: "Product",
    link: "LINK",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNTllMjNhZjMtMWRhYS00MmE0LTllNDctNDZhOTFjOWQ3MDIyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
  },
  {
    ID: "B012",
    type: "Business",
    page: "E-commerce",
    report: "Customer",
    link: "LINK",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZDliMzU0ZjMtODM2ZC00ZmIxLTkxMmYtYzJmNTMxZmI4MjZjIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
  },
  {
    ID: "B013",
    type: "Business",
    page: "E-commerce",
    report: "Attendance report",
    link: "LINK",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiN2I1MTc3YTctNjFmYi00NmJlLWEyZjAtZjVkOGQ1ODg5MDg4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
  },
  {
    ID: "B014",
    type: "Business",
    page: "E-commerce",
    report: "Retention report",
    link: "LINK",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNGI0YmQ4YzEtMzVhYS00YTM3LWJhZGMtOWZjYzVkNDNjZmFiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
  },
  {
    ID: "B015",
    type: "Business",
    page: "Finance & Banking",
    report: "RBG lending house",
    link: "LINK",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMjk3ZWRiNGEtZWY5ZS00MjI3LTg2MmMtOGU2ZjA4MTJhOGY0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
    Content:
      "Lending houses provide loans to individuals and businesses, helping people achieve their financial goals.\nThis dashboards can help users make decisions by providing them with insights into the performance of the lending house, its loan products, and its customers. It helps answer the following questions:\n1. What is the total operating income for each customer segment? \n2. How are the compay products working? Are they efficient according to the KPIs?\n3. Do the actual numbers and the forecasted numbers align? What KPIs are failing the expectations and why? ",
  },
  {
    ID: "B016",
    type: "Business",
    page: "Finance & Banking",
    report: "Income statement",
    link: "LINK",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMThjYjRjODgtYzJkMC00ZTQ0LWE3NzYtM2NlOGJiOGUxYTNlIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "B017",
    type: "Business",
    page: "Finance & Banking",
    report: "CSAT (customer support)",
    link: "LINK",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZTdmMzkwODktYzQxNC00MTdjLWI5YmEtMTk5MTNiNTc5MWY1IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
  },
  {
    ID: "B018",
    type: "Business",
    page: "Finance & Banking",
    report: "Weath management report",
    link: "LINK",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNDU5ODNiNTUtMDhlYi00Nzc3LWE4NzctZTU5MmJjODMyOWRhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
  },
  {
    ID: "B019",
    type: "Business",
    page: "Finance & Banking",
    report: "Cash flow statement",
    link: "LINK",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZDNiMjgzNGYtMWExMC00NjE2LTkxMjItZWJmYzk5ZTlmOTQyIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionc1b9058144eca6ed0c48",
    typeLink: "VN",
  },
  {
    ID: "B020",
    type: "Business",
    page: "Finance & Banking",
    report: "Credit and investment report",
    link: "LINK",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiOTY4YzdhM2ItNzQzMy00ZTRlLTgxNjktYzc3MTU3MmM4ZDI0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection5f9ebbea7fbebf7c6611",
    typeLink: "VN",
  },
  {
    ID: "B021",
    type: "Business",
    page: "Finance & Banking",
    report: "Legal customer report",
    link: "LINK",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNmE4NjljNzMtYjI0NC00MTllLTk3ZjYtODMxZTJmNDdhOWMxIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D&pageName=ReportSection",
    typeLink: "VN",
  },
  {
    ID: "B022",
    type: "Business",
    page: "Food & Beverage",
    report: "Business development",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZmQwMjMxZTEtMTUxMC00MDgxLWI5MGUtMjgzMWYzNmYzNjU5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZmQwMjMxZTEtMTUxMC00MDgxLWI5MGUtMjgzMWYzNmYzNjU5IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "B023",
    type: "Business",
    page: "Food & Beverage",
    report: "Monthly marketing report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYTRiMWZjNzMtODNkOS00YWM2LTg3YjEtNzhiZjkwYWU3MDU0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYTRiMWZjNzMtODNkOS00YWM2LTg3YjEtNzhiZjkwYWU3MDU0IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
  },
  {
    ID: "B024",
    type: "Business",
    page: "Food & Beverage",
    report: "Weekly performance report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYWVmNGU2YzQtODQ4YS00MzBkLWE0MTYtYjlkYzU0MjEwYjk4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYWVmNGU2YzQtODQ4YS00MzBkLWE0MTYtYjlkYzU0MjEwYjk4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
    Content:
      "The weekly report presents summarizing performance of all restaurants by week. This report helps C-level executives to control business status by brands and by regions which allows them to develop strategies and stay focused on the bigger picture. It also supports managers to review the essential details of number customers, sales amount of stores, gauge how restaurants meet their sales target. This report contains other indicators that support leaders to rearrange the seating in restaurants and price adjustment to improve efficiency, increase capacity or create a more inviting atmosphere.\nIt answers the below questions:\n\n1. How sales and customer number performed for that week compare to target, last week and average value?\n2. Which customer size of table has the highest frequency?\n3. Which marketing campaigns are the most and least effective?",
    Column11: "hangntm",
  },
  {
    ID: "B025",
    type: "Business",
    page: "Food & Beverage",
    report: "Human resource efficiency",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMWEyZjAxZTgtMzNmOC00NDQzLThiMjEtNjFhNWU2ZDVlMGRlIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMWEyZjAxZTgtMzNmOC00NDQzLThiMjEtNjFhNWU2ZDVlMGRlIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "B026",
    type: "Business",
    page: "Food & Beverage",
    report: "Digital Marketing Nationwide Dashboard",
    link: "Digital Marketing Nationwide Dashboard",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZDI4NGRjZjYtZDcwYi00YWFiLWI5ZWUtMTQ0MDlhYzEwMWNhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
  },
  {
    ID: "B027",
    type: "Business",
    page: "Food & Beverage",
    report: "Sales Distribution Management",
    link: "Sales Distribution Management",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiOWJmY2I3YzMtNjJhNy00MmZmLTkzOGEtOGFmM2NmYWRjNWIxIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
  },
  {
    ID: "B028",
    type: "Business",
    page: "Manufacturing",
    report: "Steel inventory situation",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZDg2Mjc3NjgtYmFmYi00MjE4LTgzZDItOWM0MzdkYjBhNDYxIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZDg2Mjc3NjgtYmFmYi00MjE4LTgzZDItOWM0MzdkYjBhNDYxIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
  {
    ID: "B029",
    type: "Business",
    page: "Manufacturing",
    report: "HSSE Leading performance indicator",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYWNhODRkZWYtNzJlOC00ZTQ4LWJkNmUtYzE1NGYyNTQ2NjE4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiYWNhODRkZWYtNzJlOC00ZTQ4LWJkNmUtYzE1NGYyNTQ2NjE4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
    Content:
      "A HSSE report tracks a company's performance in Health, Safety, Security, and Environment, identifying trends and risks to inform improvements. This report contains 7 pages and gives insights into:\n1. What are the number of hours worked by employees over a specific period of time?\n2. How is the result of Health & Safety training and Health & Safety incident investigation compared to targets and last year's numbers?\n3. How has the reduction on paper used and waste progressed? Does the complaints situation improve? ",
  },
  {
    ID: "B030",
    type: "Business",
    page: "Manufacturing",
    report: "Utility Generation",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNjU3NDYyZDQtMzNjYy00ZmYzLWI3ZGQtNDI5ZjlkNmM5YWM4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNjU3NDYyZDQtMzNjYy00ZmYzLWI3ZGQtNDI5ZjlkNmM5YWM4IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
  },
  {
    ID: "B031",
    type: "Business",
    page: "Aviation",
    report: "Aircraft",
    link: "LINK",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiOGEwYzE0MzctZmNlOS00MDI3LWJkYWEtMGI3NzE3NDQzNTNhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
    Content:
      "Aircraft report has multi-page layout, that contains 7 pages: Manufacturer's serial number level view, overall fleet summary, aircraft fleet level, age and ownership summary, engine type and deliveries details, global fleet summary. This report provides a high-level overview of the fleet, including the number of aircraft, the average age of the fleet, and the distribution of aircraft by type and model. It helps organization to make informed decisions about their fleet planning, maintenance.\nBesides, users might use this report indicators to track the performance of each fleet's porfolio. For example, the lessor could track the fuel efficiency of the aircraft, the amount of revenue they are generating, and the number of aircraft that are available for lease. This information could be used to make decisions about which aircraft to keep and which to sell.\nIt answers the below questions:\n\n1. Which aircraft need to be maintained or replaced?\n2. Which aircraft are available for lease?\n3. Which aircraft type, aircraft series are used the most?",
    Column11: "hangntm",
  },
  {
    ID: "B032",
    type: "Business",
    page: "Aviation",
    report: "Task Report",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMDY5ZGM5YWItYjZhMS00NjU4LTg5YjAtZWI0Y2Q4NzM1NzkzIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiMDY5ZGM5YWItYjZhMS00NjU4LTg5YjAtZWI0Y2Q4NzM1NzkzIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
  },
  {
    ID: "B033",
    type: "Business",
    page: "Air&Water Management",
    report: "Recycled Water Management Dashboard",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZWM4OGM0YjEtNjQyOC00YTU0LWIzODYtYzdjNTEwZGFlNDk1IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZWM4OGM0YjEtNjQyOC00YTU0LWIzODYtYzdjNTEwZGFlNDk1IiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
    Content:
      "The dashboard is for identifying concentration levels of chemicals in water in multiple areas of a country, so that needed actions are taken on time. It consists of a summary page, details page and historical details page for each location, and a very needed alert page on the chemicals with alarming concentration levels. It answers below questions:\n1. Which areas are having the most severely polluted recycled water? \n2. What specific chemicals are currently having alarming concentration levels at each location? \n3. In details, what chemicals are at the most alarming level according to their number of calibration in the last 24 hours and 48 hours? \n",
  },
  {
    ID: "B034",
    type: "Business",
    page: "Air&Water Management",
    report: "Air quality monitoring system",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNDAxYWNmM2YtNTk4OS00ODU1LWFmODUtMDQzMjZkZmFmN2VhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNDAxYWNmM2YtNTk4OS00ODU1LWFmODUtMDQzMjZkZmFmN2VhIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
  },
  {
    ID: "B035",
    type: "Business",
    page: "Others",
    report: "Recruitment Dashboards",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNTA2ZDdmNDUtMDliNi00MzU0LWE4ZTMtZjZkYWY0NDkwMjJiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiNTA2ZDdmNDUtMDliNi00MzU0LWE4ZTMtZjZkYWY0NDkwMjJiIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "EN",
  },
  {
    ID: "B036",
    type: "Business",
    page: "Others",
    report: "Project Management",
    link: "https://app.powerbi.com/view?r=eyJrIjoiZmMxMzQwNTQtM2I4ZC00NDYyLWJiNjgtOGEzMDE4OTc3NWExIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    hyperLink:
      "https://app.powerbi.com/view?r=eyJrIjoiZmMxMzQwNTQtM2I4ZC00NDYyLWJiNjgtOGEzMDE4OTc3NWExIiwidCI6IjY4ZTQxNzUxLTgwZDMtNDE4My1hZjdiLWE1YzQyMTJiZGZlNSIsImMiOjEwfQ%3D%3D",
    typeLink: "VN",
  },
];
export const totalHard = [
  {
    type: "Government",
    page: "Natural Resources and Environment",
    total: 110,
  },
  {
    type: "Government",
    page: "Education and Training",
    total: 40,
  },
  {
    type: "Government",
    page: "Transportation",
    total: 60,
  },
  {
    type: "Government",
    page: "Planning and Investment",
    total: 55,
  },
  {
    type: "Government",
    page: "Finance",
    total: 95,
  },
  {
    type: "Government",
    page: "Construction",
    total: 20,
  },
  {
    type: "Government",
    page: "Agriculture and Rural Development",
    total: 10,
  },
  {
    type: "Government",
    page: "Science and Technology",
    total: 40,
  },
  {
    type: "Government",
    page: "Industry and Trade",
    total: 15,
  },
  {
    type: "Government",
    page: "Internal Affairs",
    total: 30,
  },
  {
    type: "Government",
    page: "Labor",
    total: 5,
  },
  {
    type: "Government",
    page: "Health",
    total: 20,
  },
  {
    type: "Government",
    page: "Local Government",
    total: 145,
  },
  {
    type: "Government",
    page: "Vietnam Academy of Science and Technology",
    total: 15,
  },
  {
    type: "Government",
    page: "Vietnam Social Security",
    total: 10,
  },
  {
    type: "Business",
    page: "University",
    total: 16,
  },
  {
    type: "Business",
    page: "E-commerce",
    total: 62,
  },
  {
    type: "Business",
    page: "Finance & Banking",
    total: 28,
  },
  {
    type: "Business",
    page: "Food & Beverage",
    total: 31,
  },
  {
    type: "Business",
    page: "Manufacturing",
    total: 22,
  },
  {
    type: "Business",
    page: "Aviation",
    total: 11,
  },
  {
    type: "Business",
    page: "Air&Water Management",
    total: 15,
  },
  {
    type: "Business",
    page: "Others",
    total: 30,
  },
];

export const totalSub = [
  {
    title: "Ministry of Natural Resources and Environment (Central Level)",
    total: 68,
    type: "Government",
  },
  {
    title: "Department of Natural Resources and Environment (Provincial Level)",
    total: 42,
    type: "Government",
  },
  {
    title: "Ministry of Education and Training (Central Level)",
    total: 10,
    type: "Government",
  },
  {
    title: "Department of Education and Training (Provincial Level)",
    total: 30,
    type: "Government",
  },
  {
    title: "Ministry of Transport (Central Level)",
    total: 44,
    type: "Government",
  },
  {
    title: "Department of Transport (provincial level)",
    total: 16,
    type: "Government",
  },
  {
    title: "Department of Planning and Investment (Provincial Level)",
    total: 55,
    type: "Government",
  },
  {
    title: "Ministry of Finance (Central Level)",
    total: 56,
    type: "Government",
  },
  {
    title: "Department of Finance (Provincial Level)",
    total: 39,
    type: "Government",
  },
  {
    title: "Ministry of Construction (Central Level)",
    total: 20,
    type: "Government",
  },
  {
    title: "Ministry of Agriculture and Rural Development (Central Level)",
    total: 7,
    type: "Government",
  },
  {
    title: "Department of Agriculture and Rural Development (Provincial Level)",
    total: 3,
    type: "Government",
  },
  {
    title: "Department of Science and Technology (Provincial Level)",
    total: 40,
    type: "Government",
  },
  {
    title: "Ministry of Industry and Trade (Central Level)",
    total: 12,
    type: "Government",
  },
  {
    title: "Department of Industry and Trade (Provincial Level)",
    total: 3,
    type: "Government",
  },
  {
    title: "Ministry of Home Affairs (Central Level)",
    total: 30,
    type: "Government",
  },
  {
    title:
      "Ministry of Labour, War Invalids and Social Affairs (Central Level)",
    total: 3,
    type: "Government",
  },
  {
    title: "Department of Labor (Provincial Level)",
    total: 2,
    type: "Government",
  },
  {
    title: "Provincial Department of Health",
    total: 20,
    type: "Government",
  },
  {
    title: "E-government",
    total: 31,
    type: "Government",
  },
  {
    title: "Sectorial Summary Report",
    total: 114,
    type: "Government",
  },
  {
    title: "Vietnam Academy of Science and Technology",
    total: 15,
    type: "Government",
  },
  {
    title: "Vietnam Social Security Central",
    total: 10,
    type: "Government",
  },
];
