import React, { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../App";
import { ReactComponent as Logo } from "../../assets/Logo.svg";
import { LoginOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Col, Drawer, Row, Space } from "antd";
import IconGovernment from "../LDPIcon/IconGovernment";
import IconBusiness from "../LDPIcon/IconBusiness";
import { dataHard, totalHard, totalSub } from "../../data";
import IconSelectList from "../LDPIcon/IconSelectList";
import DownArrowIcon from "../LDPIcon/DownArrowIcon";
import { ReactComponent as ArrowLeft } from "../../assets/arrow-left.svg";

type Props = {};
type type = "Government" | "Business";

const LDPHeader = (props: Props) => {
  const navigate = useNavigate();
  const {
    setToken,
    sider: { activeMenu, activeSubMenu, typeSubMenu },
    setSider,
    iframe,
    setIframe,
  } = useContext(MyContext);

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const listType: type[] = ["Government", "Business"];

  const dataTypeSubMenu = useMemo(() => {
    const arr = Array.from(
      new Set(
        dataHard
          .filter((e) => e.type === activeMenu && e.page === activeSubMenu)
          .map((e) => e.title)
      )
    );

    return arr.every((e) => e) ? arr : [];
  }, [activeMenu, activeSubMenu]);

  const dataSubmenu = useMemo(() => {
    return Array.from(
      new Set(dataHard.filter((e) => e.type === activeMenu).map((e) => e.page))
    );
  }, [activeMenu]);

  return (
    <div
      style={{
        padding: "16px",
        backgroundColor: "#fff",
        borderBottom: "1px solid #B1B1B1",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Logo onClick={() => navigate("/")} style={{ cursor: "pointer" }} />
      <Space size={16}>
        {iframe && (
          <ArrowLeft
            style={{ cursor: "pointer" }}
            onClick={() => setIframe("")}
          />
        )}
        <MenuFoldOutlined onClick={() => setOpenMenu(true)} />
      </Space>
      <Drawer
        // className="hiden_block_992"
        placement="right"
        title={
          <div style={{ display: "flex", justifyContent: "end" }}>
            <div
              style={{
                padding: "14px",
                color: "#111",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 600,
                cursor: "pointer",
              }}
              onClick={() => {
                setToken("");
                localStorage.removeItem("token");
              }}
            >
              <LoginOutlined />
              <span>Logout</span>
            </div>
          </div>
        }
        onClose={() => setOpenMenu(false)}
        open={openMenu}
        bodyStyle={{ padding: "0px" }}
        rootStyle={{
          width: "100wh",
        }}
      >
        <Row>
          <Col
            span={24}
            style={{
              background: "#fff",
            }}
          >
            <Row>
              {listType.map((type, index) => (
                <Col
                  span={12}
                  onClick={() => {
                    setSider({
                      activeMenu: type,
                      activeSubMenu: "",
                      typeSubMenu: "",
                    });
                    // setOpenMenu(false);
                  }}
                  className={
                    activeMenu === type
                      ? "menu-left-sider menu-left-sider-active"
                      : "menu-left-sider"
                  }
                  style={{
                    padding: "16px",
                    gap: "10px",
                  }}
                >
                  {type === "Government" ? (
                    <IconGovernment />
                  ) : (
                    <IconBusiness />
                  )}
                  <span
                    style={{
                      flexGrow: 1,
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 700,
                    }}
                    className="menu-left-sider__content"
                  >
                    <p>{type}</p>
                    <p
                      style={{
                        color: "#838383",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 700,
                      }}
                    >
                      (
                      {totalHard
                        .filter((e) => e.type === type)
                        .reduce((accumulator, currentValue) => {
                          return accumulator + currentValue.total;
                        }, 0)}
                      )
                    </p>
                  </span>
                </Col>
              ))}
            </Row>
          </Col>
          <Col
            span={24}
            style={{
              borderLeft: "1px solid #B1B1B1",
              overflow: "auto",
              // height: `calc(100% - ${heightLogo}px)`,
            }}
          >
            {dataSubmenu.map((e, index) => (
              <div
                key={index}
                style={{
                  borderBottom: "1px solid #B1B1B1",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    padding: "20px 26px",
                    cursor: "pointer",
                    background: e === activeSubMenu ? "#F9F9F9" : "transparent",
                  }}
                  onClick={() => {
                    setSider({
                      activeMenu: activeMenu,
                      activeSubMenu: activeSubMenu === e ? "" : e,
                      typeSubMenu: "",
                    });
                    // setOpenMenu(false);
                  }}
                >
                  <IconSelectList keyIcon={e} />
                  <div
                    style={{
                      flexGrow: 1,
                      color: "#111",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 700,
                    }}
                  >
                    {`${e} `}
                    <span
                      style={{
                        color: "#838383",
                      }}
                    >
                      ({totalHard.find((el) => el.page === e)?.total || 0})
                    </span>
                  </div>

                  <DownArrowIcon
                    className={
                      e === activeSubMenu
                        ? "menu-right-sider-icon-active "
                        : "menu-right-sider-icon "
                    }
                  />
                </div>

                {activeSubMenu === e &&
                  dataTypeSubMenu.map((e, index) => (
                    <div
                      key={index}
                      style={{
                        padding: "20px 26px",
                        cursor: "pointer",
                        background: typeSubMenu === e ? "#DFDFDF" : "#F9F9F9",
                        fontSize: "14px",
                        color: "#111",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                      onClick={() => {
                        setSider({
                          activeMenu: activeMenu,
                          activeSubMenu: activeSubMenu,
                          typeSubMenu: !e || typeSubMenu === e ? "" : e,
                        });
                        // setOpenMenu(false);
                      }}
                    >
                      {`${e} `}
                      <span
                        style={{
                          color: "#838383",
                        }}
                      >
                        ({totalSub.find((el) => el.title === e)?.total || 0})
                      </span>
                    </div>
                  ))}
              </div>
            ))}
          </Col>
        </Row>
      </Drawer>
    </div>
  );
};

export default LDPHeader;
