import Icon from "@ant-design/icons";
type Props = {};

const IconCustom = () => (
  <svg
    width="34"
    height="35"
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="48px_office">
      <path
        id="Vector"
        d="M32.5833 15.375H21.25C20.8583 15.375 20.5416 15.6916 20.5416 16.0833V33.0833C20.5416 33.475 20.8583 33.7917 21.25 33.7917H32.5833C32.975 33.7917 33.2916 33.475 33.2916 33.0833V16.0833C33.2916 15.6916 32.975 15.375 32.5833 15.375ZM29.0416 29.5417H24.7916C24.3999 29.5417 24.0833 29.225 24.0833 28.8333C24.0833 28.4416 24.3999 28.125 24.7916 28.125H29.0416C29.4333 28.125 29.75 28.4416 29.75 28.8333C29.75 29.225 29.4333 29.5417 29.0416 29.5417ZM29.0416 25.2917H24.7916C24.3999 25.2917 24.0833 24.975 24.0833 24.5833C24.0833 24.1916 24.3999 23.875 24.7916 23.875H29.0416C29.4333 23.875 29.75 24.1916 29.75 24.5833C29.75 24.975 29.4333 25.2917 29.0416 25.2917ZM29.0416 21.0417H24.7916C24.3999 21.0417 24.0833 20.725 24.0833 20.3333C24.0833 19.9416 24.3999 19.625 24.7916 19.625H29.0416C29.4333 19.625 29.75 19.9416 29.75 20.3333C29.75 20.725 29.4333 21.0417 29.0416 21.0417Z"
        fill="#111111"
      />
      <path
        id="Vector_2"
        d="M25.5 4.04167H17.7083V1.91667C17.7083 1.52496 17.3917 1.20834 17 1.20834C16.6083 1.20834 16.2916 1.52496 16.2916 1.91667V4.04167H8.49996C8.10896 4.04167 7.79163 4.35901 7.79163 4.75001V18.2083H13.4583C14.241 18.2083 14.875 18.8423 14.875 19.625V33.7917H19.125V15.375C19.125 14.5923 19.7589 13.9583 20.5416 13.9583H26.2083V4.75001C26.2083 4.35901 25.891 4.04167 25.5 4.04167ZM19.8333 11.125H14.1666C13.7749 11.125 13.4583 10.8084 13.4583 10.4167C13.4583 10.025 13.7749 9.70834 14.1666 9.70834H19.8333C20.225 9.70834 20.5416 10.025 20.5416 10.4167C20.5416 10.8084 20.225 11.125 19.8333 11.125Z"
        fill="#111111"
      />
      <path
        id="Vector_3"
        d="M12.75 19.625H1.41671C1.025 19.625 0.708374 19.9416 0.708374 20.3333V33.0833C0.708374 33.475 1.025 33.7917 1.41671 33.7917H12.75C13.1417 33.7917 13.4584 33.475 13.4584 33.0833V20.3333C13.4584 19.9416 13.1417 19.625 12.75 19.625ZM9.20837 29.5417H4.95837C4.56667 29.5417 4.25004 29.225 4.25004 28.8333C4.25004 28.4416 4.56667 28.125 4.95837 28.125H9.20837C9.60008 28.125 9.91671 28.4416 9.91671 28.8333C9.91671 29.225 9.60008 29.5417 9.20837 29.5417ZM9.20837 25.2917H4.95837C4.56667 25.2917 4.25004 24.975 4.25004 24.5833C4.25004 24.1916 4.56667 23.875 4.95837 23.875H9.20837C9.60008 23.875 9.91671 24.1916 9.91671 24.5833C9.91671 24.975 9.60008 25.2917 9.20837 25.2917Z"
        fill="#111111"
      />
    </g>
  </svg>
);

const IconBusiness = (props: Props) => {
  return <Icon component={IconCustom} {...props} />;
};

export default IconBusiness;
