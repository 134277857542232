import React, { useContext } from "react";
import { Form, Input, Button, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../App";

const Login = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const { setToken } = useContext(MyContext);

  console.log(process.env.REACT_APP_USER);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {contextHolder}
      <div>
        <p
          style={{
            color: "#222222",
            fontSize: "36px",
            fontStyle: "normal",
            fontWeight: 800,
            textAlign: "center",
          }}
        >
          Login
        </p>
        <Form
          style={{
            maxWidth: "450px",
          }}
          onFinish={(e) => {
            const { password, username } = e;
            if (
              username === process.env.REACT_APP_USER &&
              password === process.env.REACT_APP_PASS
            ) {
              messageApi.open({
                type: "success",
                content: "log in successfully",
              });
              setToken("token");
              localStorage.setItem("token", "token");
              navigate("/");
            }
            messageApi.open({
              type: "error",
              content: "Account or password is incorrect",
            });
          }}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              size="large"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block htmlType="submit">
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
