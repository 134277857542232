import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./page/Login/Index";
import React, { useState, useMemo } from "react";
import HomePage from "./page/Home";

type typeSider = {
  activeMenu: string;
  activeSubMenu: string;
  typeSubMenu: string;
};

type typeGlobal = {
  token: string;
  setToken: (token: string) => void;
  sider: typeSider;
  setSider: (obj: typeSider) => void;
  setIframe: (link: string) => void;
  iframe: string;
};

export const MyContext = React.createContext<typeGlobal>({
  token: "",
  setToken: () => {},
  setSider: () => {},
  sider: {
    activeMenu: "Government",
    activeSubMenu: "",
    typeSubMenu: "",
  },
  iframe: "",
  setIframe: () => {},
});

function App() {
  const [globalState, setGlobalState] = useState<{
    token: string;
    sider: typeSider;
    iframe: string;
  }>({
    token: localStorage.getItem("token") || "",
    sider: {
      activeMenu: "Government",
      activeSubMenu: "",
      typeSubMenu: "",
    },
    iframe: "",
  });

  const memoizedValue = useMemo<typeGlobal>(
    () => ({
      token: globalState.token,
      setToken(t) {
        setGlobalState({ ...globalState, token: t });
      },
      sider: globalState.sider,
      setSider(obj) {
        setGlobalState({ ...globalState, sider: obj });
      },
      iframe: globalState.iframe,
      setIframe(link) {
        setGlobalState({ ...globalState, iframe: link });
      },
    }),
    [globalState]
  );

  const { token } = globalState;

  return (
    <MyContext.Provider value={memoizedValue}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            element={token ? <Navigate to="/" replace /> : <Login />}
          />
          <Route
            path="/"
            element={token ? <HomePage /> : <Navigate to="/login" replace />}
          />
        </Routes>
      </BrowserRouter>
    </MyContext.Provider>
  );
}

export default App;
